import { DeleteOutlined, DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { Button, Image, Upload, UploadFile } from "antd";
import { useRef, useState } from "react";
import {
  deleteDocumentApi,
  generateDocumentPresignedUrl,
  getDocumentByIdApi,
  updateDocumentStatus,
  uploadFileToPresignUrl,
} from "../../../api/documentAPI";
import { useAppSelector } from "../../../app/hooks";
import { useDocumentsOnLoadCallback, useFormTranslation } from "../../../hooks/commonHooks";
import { DocumentOperation, DocumentType, Identity } from "../../../model/commonModels";
import { handleFileDownload } from "../../../utils/functionUtils";
import { ErrorMessage, QText } from "../../common/Fonts";
import { Loading } from "../../common/Loading";
import { CheckBox } from "./Controls";
import "./MultiFileUploader.css";
import { FileType } from "./Uploader";

export interface MultiFileUploaderProps {
  documentType: DocumentType;
  identity: Identity;
  operation?: DocumentOperation;
  description?: string;
  documentIds?: number[];
  onChange: (documentIds: number[]) => void;
  enableNACheckbox?: boolean;
}

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export function MultiFileUploader(props: MultiFileUploaderProps) {
  const { t } = useFormTranslation();

  const userId = useAppSelector(state => state.auth.userId);
  const caseId = useAppSelector(state => state.form.caseId);
  const accessToken = useAppSelector(state => state.auth.accessToken);
  const role = useAppSelector(state => state.auth.role);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [uploaderIsDisabled, setUploaderIdDisabled] = useState(props.documentIds?.[0] === -1);

  const documentIds = useRef<number[]>(props.documentIds || []);

  useDocumentsOnLoadCallback({
    caseId: caseId,
    accessToken: accessToken,
    role: role,
    setLoading: setLoading,
    skip: uploaderIsDisabled,
    onDocumentsReceived: documents => {
      // Remove the documentId that is not in documents from the documentIds current
      documentIds.current = documentIds.current.filter(id => documents.some(doc => doc.id === id));
      props.onChange(documentIds.current);

      const newFileList = documents
        .filter(doc => documentIds.current.indexOf(doc.id) > -1)
        .map(doc => ({
          uid: doc.id.toString(),
          name: doc.name,
          status: "done",
          type: /\.(jpg|jpeg|png|gif)$/i.test(doc.name) ? "image/jpeg" : "application/pdf",
        }));
      setFileList(newFileList);
    },
    additionalParams: {
      type: props.documentType,
      identity: props.identity,
      generationType: "USER_UPLOADED",
      autoGenerated: false,
    },
  });

  const uploadWithPresignedUrl = async options => {
    const { file, onSuccess, onError, onProgress } = options;

    try {
      if (!userId || !caseId || !accessToken) {
        throw new Error("User id, case id or access token is missing");
      }
      setErrorMessage("");
      const res = await generateDocumentPresignedUrl(
        userId,
        caseId,
        props.documentType,
        file.name,
        props.identity,
        props.operation || "NEW",
        props.description || "",
        role,
        accessToken,
        role,
      );

      file.uid = res.documentId.toString();
      setFileList([...fileList, file]);

      try {
        documentIds.current.push(res.documentId);
      } catch (error) {
        if (error instanceof TypeError) {
          documentIds.current = [...documentIds.current, res.documentId];
        } else {
          throw error; // Re-throw if it's not the specific TypeError we're catching
        }
      }
      props.onChange(documentIds.current);

      uploadFileToPresignUrl(
        res.presignedUrl,
        file,
        onProgress,
        () => {
          updateDocumentStatus(role, res.documentId, true, "UPLOADED", accessToken, caseId);
          onSuccess(res, file, null);
        },
        () => {
          updateDocumentStatus(role, res.documentId, false, "FAILED", accessToken, caseId);
          onError(new Error("Failed to upload the file, please try again."));
        },
      );
    } catch (error) {
      setErrorMessage("Failed to upload the file, please try again.");
      onError(error);
      console.error(error);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (file.name.indexOf("pdf") > -1) {
      return;
    }
    const doc = await getDocumentByIdApi(accessToken!, parseInt(file.uid), role, caseId);
    file.url = doc.presignUrl;

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleRemove = async (file: UploadFile) => {
    try {
      if (!userId || !caseId || !accessToken) {
        throw new Error("User id, case id or access token is missing");
      }
      const documentId = parseInt(file.uid);
      documentIds.current = documentIds.current.filter(id => id !== documentId);
      setFileList(fileList.filter(f => f.uid !== file.uid));
      props.onChange(documentIds.current);
      await deleteDocumentApi(role, documentId, accessToken, caseId);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  const enabledUploader = (
    <>
      <Upload
        name="uploader"
        listType="picture-card"
        className="uploader"
        customRequest={uploadWithPresignedUrl}
        fileList={fileList}
        itemRender={(originNode, file, _currFileList) => {
          const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file.name);
          return (
            <div className="upload-item-container">
              {originNode}
              <div className="upload-item-actions">
                <Button
                  type="link"
                  icon={isImage ? <EyeOutlined /> : <DownloadOutlined />}
                  onClick={() => {
                    isImage ? handlePreview(file) : handleFileDownload(file, accessToken!, role, caseId);
                  }}
                  style={{ marginLeft: "auto" }}
                />
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove(file)}
                  style={{ marginLeft: "auto" }}
                />
              </div>
            </div>
          );
        }}
      >
        <div className="uploader-upload">
          <PlusOutlined />
          <QText level="upload">{t("Upload")}</QText>
        </div>
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: visible => setPreviewOpen(visible),
            afterOpenChange: visible => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );

  const disabledUploader = (
    <div className="uploader-disabled">
      <div className="uploader-upload">
        <PlusOutlined />
        <QText level="upload" color="gray">
          Upload
        </QText>
      </div>
    </div>
  );

  return (
    <div className="miltifile-uploader-container">
      <div className="miltifile-uploader-top">
        {props.enableNACheckbox && uploaderIsDisabled ? disabledUploader : enabledUploader}
        {props.enableNACheckbox && (
          <CheckBox
            label={t("NotApplicableText")}
            checked={uploaderIsDisabled}
            onChange={(value: any) => {
              if (props.documentIds && props.documentIds.length > 0) {
                props.documentIds.forEach(documentId => {
                  if (documentId === -1 || documentId === 0) return;
                  deleteDocumentApi(role, documentId, accessToken!, caseId).catch(error => {
                    console.error(error);
                  });
                });
              }
              props.onChange(value === true ? [-1] : []);
              setUploaderIdDisabled(value === true);
            }}
          />
        )}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}
