export const Logo = () => (
  <svg width="120" height="32" viewBox="0 0 640 168" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M376.674 53.3173C370.709 53.3173 365.715 48.3235 365.715 42.3585C365.715 36.3936 370.709 31.261 376.674 31.261C382.777 31.261 387.771 36.3936 387.771 42.3585C387.771 48.3235 382.777 53.3173 376.674 53.3173ZM367.796 131V61.6405H385.691V131H367.796ZM470.458 59.6984C486.272 59.6984 496.676 70.9347 496.676 88.2745V131H478.781V89.523C478.781 81.3386 474.619 76.3447 467.128 76.3447C459.221 76.3447 454.227 81.616 454.227 92.0199V131H436.333V89.523C436.333 81.3386 432.171 76.3447 424.68 76.3447C417.051 76.3447 411.641 81.616 411.641 92.0199V131H393.746V61.6405H411.641V68.9926C415.525 63.1664 422.045 59.6984 430.923 59.6984C439.523 59.6984 445.904 63.3051 449.788 69.6862C454.089 63.4438 461.025 59.6984 470.458 59.6984ZM580.262 59.6984C596.076 59.6984 606.48 70.9347 606.48 88.2745V131H588.586V89.523C588.586 81.3386 584.424 76.3447 576.933 76.3447C569.026 76.3447 564.032 81.616 564.032 92.0199V131H546.138V89.523C546.138 81.3386 541.976 76.3447 534.485 76.3447C526.856 76.3447 521.446 81.616 521.446 92.0199V131H503.551V61.6405H521.446V68.9926C525.33 63.1664 531.849 59.6984 540.727 59.6984C549.328 59.6984 555.709 63.3051 559.593 69.6862C563.894 63.4438 570.829 59.6984 580.262 59.6984ZM622.234 53.3173C616.269 53.3173 611.275 48.3235 611.275 42.3585C611.275 36.3936 616.269 31.261 622.234 31.261C628.337 31.261 633.331 36.3936 633.331 42.3585C633.331 48.3235 628.337 53.3173 622.234 53.3173ZM613.356 131V61.6405H631.25V131H613.356Z"
      fill="#333333"
    />
    <path
      d="M182.776 61.6405H200.671V131H182.776V123.232C178.615 129.474 171.401 132.942 162.107 132.942C147.403 132.942 135.889 122.677 135.889 104.227V61.6405H153.784V102.008C153.784 111.441 159.471 116.296 167.517 116.296C176.256 116.296 182.776 111.163 182.776 99.0946V61.6405ZM217.615 53.3173C211.65 53.3173 206.656 48.3235 206.656 42.3585C206.656 36.3936 211.65 31.261 217.615 31.261C223.719 31.261 228.713 36.3936 228.713 42.3585C228.713 48.3235 223.719 53.3173 217.615 53.3173ZM208.737 131V61.6405H226.632V131H208.737ZM267.703 132.942C246.617 132.942 231.081 116.989 231.081 96.3202C231.081 75.6511 246.617 59.6984 267.703 59.6984C281.297 59.6984 293.088 66.9118 298.776 77.5932L283.378 86.6099C280.603 80.9224 274.639 77.3157 267.564 77.3157C256.882 77.3157 248.975 85.2227 248.975 96.3202C248.975 107.279 256.882 115.186 267.564 115.186C274.777 115.186 280.742 111.718 283.517 106.031L299.053 114.909C293.088 125.729 281.297 132.942 267.703 132.942Z"
      fill="#333333"
    />
    <path d="M310.251 32.3916H329.99L319.609 131.598H299.87L310.251 32.3916Z" fill="#333333" />
    <path
      d="M306.404 97.1641H330.251L361.898 131.597H343.451C340.009 131.597 336.725 130.155 334.396 127.62L306.404 97.1641Z"
      fill="#27AE60"
    />
    <path
      d="M306.404 97.1641H330.251L361.898 62.7313H343.451C340.009 62.7313 336.725 64.1735 334.396 66.7077L306.404 97.1641Z"
      fill="#27AE60"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5976 69.4577C24.5976 47.7219 42.218 30.1015 63.9538 30.1015C85.6896 30.1015 103.31 47.7219 103.31 69.4577C103.31 79.7747 99.3401 89.1644 92.8447 96.1828C91.8372 95.1287 90.8442 94.1255 89.8677 93.1791C74.9268 78.6993 60.3506 86.415 60.3506 86.415C60.3506 86.415 52.3564 83.3401 46.6102 84.9953C42.6516 86.1355 38.8277 89.6143 38.8277 89.6143C38.8277 89.6143 45.2879 88.5408 51.5186 92.4504C67.1244 102.242 73.8831 133.411 73.8831 133.411H121.594C121.594 133.411 116.139 124.644 108.779 115.073C120.585 103.47 127.908 87.319 127.908 69.4577C127.908 34.137 99.2744 5.50391 63.9538 5.50391C28.6331 5.50391 0 34.137 0 69.4577C0 104.778 28.6331 133.411 63.9538 133.411L43.5563 109.355C25.8426 88.5053 24.5976 78.6472 24.5976 69.4577Z"
      fill="#27AE60"
    />
  </svg>
);

export const QuickImmi = () => (
  <svg viewBox="0 0 640 168" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M376.674 53.3173C370.709 53.3173 365.715 48.3235 365.715 42.3585C365.715 36.3936 370.709 31.261 376.674 31.261C382.777 31.261 387.771 36.3936 387.771 42.3585C387.771 48.3235 382.777 53.3173 376.674 53.3173ZM367.796 131V61.6405H385.691V131H367.796ZM470.458 59.6984C486.272 59.6984 496.676 70.9347 496.676 88.2745V131H478.781V89.523C478.781 81.3386 474.619 76.3447 467.128 76.3447C459.221 76.3447 454.227 81.616 454.227 92.0199V131H436.333V89.523C436.333 81.3386 432.171 76.3447 424.68 76.3447C417.051 76.3447 411.641 81.616 411.641 92.0199V131H393.746V61.6405H411.641V68.9926C415.525 63.1664 422.045 59.6984 430.923 59.6984C439.523 59.6984 445.904 63.3051 449.788 69.6862C454.089 63.4438 461.025 59.6984 470.458 59.6984ZM580.262 59.6984C596.076 59.6984 606.48 70.9347 606.48 88.2745V131H588.586V89.523C588.586 81.3386 584.424 76.3447 576.933 76.3447C569.026 76.3447 564.032 81.616 564.032 92.0199V131H546.138V89.523C546.138 81.3386 541.976 76.3447 534.485 76.3447C526.856 76.3447 521.446 81.616 521.446 92.0199V131H503.551V61.6405H521.446V68.9926C525.33 63.1664 531.849 59.6984 540.727 59.6984C549.328 59.6984 555.709 63.3051 559.593 69.6862C563.894 63.4438 570.829 59.6984 580.262 59.6984ZM622.234 53.3173C616.269 53.3173 611.275 48.3235 611.275 42.3585C611.275 36.3936 616.269 31.261 622.234 31.261C628.337 31.261 633.331 36.3936 633.331 42.3585C633.331 48.3235 628.337 53.3173 622.234 53.3173ZM613.356 131V61.6405H631.25V131H613.356Z"
      fill="#333333"
    />
    <path
      d="M182.776 61.6405H200.671V131H182.776V123.232C178.615 129.474 171.401 132.942 162.107 132.942C147.403 132.942 135.889 122.677 135.889 104.227V61.6405H153.784V102.008C153.784 111.441 159.471 116.296 167.517 116.296C176.256 116.296 182.776 111.163 182.776 99.0946V61.6405ZM217.615 53.3173C211.65 53.3173 206.656 48.3235 206.656 42.3585C206.656 36.3936 211.65 31.261 217.615 31.261C223.719 31.261 228.713 36.3936 228.713 42.3585C228.713 48.3235 223.719 53.3173 217.615 53.3173ZM208.737 131V61.6405H226.632V131H208.737ZM267.703 132.942C246.617 132.942 231.081 116.989 231.081 96.3202C231.081 75.6511 246.617 59.6984 267.703 59.6984C281.297 59.6984 293.088 66.9118 298.776 77.5932L283.378 86.6099C280.603 80.9224 274.639 77.3157 267.564 77.3157C256.882 77.3157 248.975 85.2227 248.975 96.3202C248.975 107.279 256.882 115.186 267.564 115.186C274.777 115.186 280.742 111.718 283.517 106.031L299.053 114.909C293.088 125.729 281.297 132.942 267.703 132.942Z"
      fill="#333333"
    />
    <path d="M310.251 32.3916H329.99L319.609 131.598H299.87L310.251 32.3916Z" fill="#333333" />
    <path
      d="M306.404 97.1641H330.251L361.898 131.597H343.451C340.009 131.597 336.725 130.155 334.396 127.62L306.404 97.1641Z"
      fill="#27AE60"
    />
    <path
      d="M306.404 97.1641H330.251L361.898 62.7313H343.451C340.009 62.7313 336.725 64.1735 334.396 66.7077L306.404 97.1641Z"
      fill="#27AE60"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5976 69.4577C24.5976 47.7219 42.218 30.1015 63.9538 30.1015C85.6896 30.1015 103.31 47.7219 103.31 69.4577C103.31 79.7747 99.3401 89.1644 92.8447 96.1828C91.8372 95.1287 90.8442 94.1255 89.8677 93.1791C74.9268 78.6993 60.3506 86.415 60.3506 86.415C60.3506 86.415 52.3564 83.3401 46.6102 84.9953C42.6516 86.1355 38.8277 89.6143 38.8277 89.6143C38.8277 89.6143 45.2879 88.5408 51.5186 92.4504C67.1244 102.242 73.8831 133.411 73.8831 133.411H121.594C121.594 133.411 116.139 124.644 108.779 115.073C120.585 103.47 127.908 87.319 127.908 69.4577C127.908 34.137 99.2744 5.50391 63.9538 5.50391C28.6331 5.50391 0 34.137 0 69.4577C0 104.778 28.6331 133.411 63.9538 133.411L43.5563 109.355C25.8426 88.5053 24.5976 78.6472 24.5976 69.4577Z"
      fill="#27AE60"
    />
  </svg>
);

export const QuickImmiLight = () => (
  <svg width="205" height="54" viewBox="0 0 205 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M120.376 17.1744C118.47 17.1744 116.874 15.5785 116.874 13.6723C116.874 11.766 118.47 10.1258 120.376 10.1258C122.327 10.1258 123.923 11.766 123.923 13.6723C123.923 15.5785 122.327 17.1744 120.376 17.1744ZM117.539 42V19.8343H123.258V42H117.539ZM150.347 19.2137C155.401 19.2137 158.726 22.8045 158.726 28.3459V42H153.007V28.7449C153.007 26.1294 151.677 24.5334 149.283 24.5334C146.756 24.5334 145.16 26.218 145.16 29.5429V42H139.442V28.7449C139.442 26.1294 138.112 24.5334 135.718 24.5334C133.28 24.5334 131.551 26.218 131.551 29.5429V42H125.832V19.8343H131.551V22.1839C132.792 20.322 134.876 19.2137 137.713 19.2137C140.461 19.2137 142.501 20.3663 143.742 22.4055C145.116 20.4106 147.333 19.2137 150.347 19.2137ZM185.438 19.2137C190.492 19.2137 193.817 22.8045 193.817 28.3459V42H188.098V28.7449C188.098 26.1294 186.768 24.5334 184.374 24.5334C181.847 24.5334 180.251 26.218 180.251 29.5429V42H174.533V28.7449C174.533 26.1294 173.203 24.5334 170.809 24.5334C168.371 24.5334 166.642 26.218 166.642 29.5429V42H160.923V19.8343H166.642V22.1839C167.883 20.322 169.967 19.2137 172.804 19.2137C175.552 19.2137 177.592 20.3663 178.833 22.4055C180.207 20.4106 182.424 19.2137 185.438 19.2137ZM198.851 17.1744C196.945 17.1744 195.349 15.5785 195.349 13.6723C195.349 11.766 196.945 10.1258 198.851 10.1258C200.802 10.1258 202.398 11.766 202.398 13.6723C202.398 15.5785 200.802 17.1744 198.851 17.1744ZM196.014 42V19.8343H201.733V42H196.014Z"
      fill="white"
    />
    <path
      d="M58.411 19.8343H64.1297V42H58.411V39.5174C57.081 41.5124 54.7758 42.6206 51.8056 42.6206C47.1065 42.6206 43.427 39.3401 43.427 33.4441V19.8343H49.1457V32.7347C49.1457 35.7493 50.9633 37.3009 53.5345 37.3009C56.3274 37.3009 58.411 35.6606 58.411 31.8038V19.8343ZM69.5447 17.1744C67.6385 17.1744 66.0425 15.5785 66.0425 13.6723C66.0425 11.766 67.6385 10.1258 69.5447 10.1258C71.4953 10.1258 73.0912 11.766 73.0912 13.6723C73.0912 15.5785 71.4953 17.1744 69.5447 17.1744ZM66.7075 42V19.8343H72.4263V42H66.7075ZM85.5515 42.6206C78.8131 42.6206 73.848 37.5225 73.848 30.9172C73.848 24.3118 78.8131 19.2137 85.5515 19.2137C89.8959 19.2137 93.6641 21.5189 95.4817 24.9324L90.5609 27.814C89.6743 25.9964 87.768 24.8438 85.5071 24.8438C82.0936 24.8438 79.5667 27.3707 79.5667 30.9172C79.5667 34.4193 82.0936 36.9462 85.5071 36.9462C87.8123 36.9462 89.7186 35.8379 90.6052 34.0204L95.5703 36.8576C93.6641 40.3154 89.8959 42.6206 85.5515 42.6206Z"
      fill="white"
    />
    <path d="M99.1489 10.3516H105.457L102.139 42.0554H95.8314L99.1489 10.3516Z" fill="white" />
    <path
      d="M97.9196 31.0513H105.541L115.654 42.0552H109.759C108.659 42.0552 107.609 41.5943 106.865 40.7844L97.9196 31.0513Z"
      fill="#27AE60"
    />
    <path
      d="M97.9196 31.0513H105.541L115.654 20.0474H109.759C108.659 20.0474 107.609 20.5083 106.865 21.3181L97.9196 31.0513Z"
      fill="#27AE60"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.86081 22.1969C7.86081 15.2507 13.4919 9.6196 20.4381 9.6196C27.3844 9.6196 33.0154 15.2507 33.0154 22.1969C33.0154 25.494 31.7468 28.4947 29.671 30.7376C29.349 30.4008 29.0317 30.0802 28.7196 29.7777C23.9449 25.1503 19.2866 27.6161 19.2866 27.6161C19.2866 27.6161 16.7319 26.6334 14.8955 27.1624C13.6304 27.5268 12.4084 28.6385 12.4084 28.6385C12.4084 28.6385 14.473 28.2954 16.4641 29.5448C21.4514 32.6742 23.6113 42.6349 23.6113 42.6349H38.8587C38.8587 42.6349 37.1151 39.8331 34.7632 36.7745C38.5363 33.0665 40.8762 27.905 40.8762 22.1969C40.8762 10.9092 31.7258 1.75879 20.4381 1.75879C9.15046 1.75879 0 10.9092 0 22.1969C0 33.4846 9.15046 42.635 20.4381 42.635L13.9196 34.9472C8.25868 28.2841 7.86081 25.1337 7.86081 22.1969Z"
      fill="#27AE60"
    />
  </svg>
);

export const BrandQ = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="18" fill="#27AE60" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1176 17.7057C11.1176 14.0672 14.0672 11.1176 17.7057 11.1176C21.3442 11.1176 24.2938 14.0672 24.2938 17.7057C24.2938 19.4328 23.6293 21.0046 22.542 22.1795C22.3733 22.003 22.2071 21.8351 22.0436 21.6767C19.5426 19.2528 17.1025 20.5444 17.1025 20.5444C17.1025 20.5444 15.7643 20.0296 14.8024 20.3067C14.1398 20.4976 13.4997 21.0799 13.4997 21.0799C13.4997 21.0799 14.5811 20.9002 15.6241 21.5547C18.2365 23.1938 19.3679 28.4114 19.3679 28.4114H27.3546C27.3546 28.4114 26.4413 26.9438 25.2094 25.3417C27.1857 23.3993 28.4114 20.6957 28.4114 17.7057C28.4114 11.7931 23.6183 7 17.7057 7C11.7931 7 7 11.7931 7 17.7057C7 23.6183 11.7931 28.4114 17.7057 28.4114L14.2912 24.3845C11.326 20.8942 11.1176 19.244 11.1176 17.7057Z"
      fill="white"
    />
  </svg>
);
