export const ContactUsIcon = () => (
  <svg viewBox="0 0 400 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M107.425 101.2C107.425 101.2 107.345 95.3598 125.505 85.6798C143.665 75.9998 171.105 78.5599 180.705 84.3199C190.305 90.0799 195.025 91.1999 198.945 103.92C201.345 112 202.625 120.32 202.705 128.72H104.385L107.425 101.2Z"
      fill="#27AE60"
    />
    <path
      d="M141.905 111.52C144.065 113.28 153.745 115.04 155.985 114.48C161.905 112.96 168.145 110.88 171.825 105.68C173.905 102.8 174.865 99.1998 175.665 95.6798C176.545 91.5998 177.265 87.1998 176.145 83.1998C172.145 69.5998 151.185 68.1598 140.705 73.6798C125.585 81.6798 131.345 102.72 141.905 111.52Z"
      fill="white"
    />
    <path
      d="M154.705 115.12C151.025 115.12 143.505 113.52 141.585 111.92C134.625 106.08 130.145 95.5998 131.105 87.0398C131.585 81.1198 135.105 75.9198 140.385 73.1998C147.585 69.3598 158.545 69.0398 166.465 72.3998C171.745 74.6398 175.265 78.3198 176.625 83.0398C177.825 87.1998 177.105 91.5998 176.225 95.8398C175.505 99.1198 174.545 102.96 172.305 106C168.625 111.2 162.385 113.36 156.145 114.96C155.665 115.12 155.185 115.2 154.705 115.12ZM142.305 111.12C144.225 112.72 153.665 114.48 155.825 113.92C161.825 112.4 167.905 110.24 171.345 105.36C173.425 102.4 174.385 98.7998 175.025 95.5998C175.905 91.5198 176.625 87.1998 175.505 83.3598C174.225 78.9598 170.945 75.5198 165.985 73.3598C158.465 70.1598 147.745 70.5598 140.865 74.1598C135.905 76.6398 132.625 81.5998 132.145 87.1198C131.265 95.4398 135.585 105.52 142.305 111.12Z"
      fill="#231F20"
    />
    <path
      d="M147.185 23.2797C137.025 25.0397 127.905 31.1997 128.065 42.5597C128.225 49.5197 131.905 55.9997 137.185 60.5597C144.865 67.2797 156.145 69.9197 165.585 66.1597C173.585 62.9597 179.665 54.9597 180.145 46.3197C180.545 39.4397 177.505 28.2397 170.305 25.7597C167.585 24.7997 164.145 26.9597 164.145 26.9597C162.705 23.0397 151.425 22.5597 147.185 23.2797Z"
      fill="white"
    />
    <path
      d="M156.465 68.3997C149.265 68.3997 142.305 65.7597 136.785 61.0397C131.025 55.9997 127.665 49.2797 127.505 42.6397C127.345 32.3997 134.625 25.0397 147.025 22.7997C151.265 22.0797 162.065 22.4797 164.225 26.3197C165.345 25.7597 167.985 24.5597 170.305 25.3597C177.585 27.8397 180.945 39.1997 180.545 46.4797C180.065 55.1997 173.985 63.5197 165.665 66.7997C162.785 67.9197 159.585 68.4797 156.465 68.3997ZM151.265 23.5197C149.905 23.5197 148.545 23.5997 147.185 23.8397C135.345 25.9197 128.305 32.9597 128.465 42.6397C128.545 49.0397 131.825 55.4397 137.345 60.3197C145.105 67.1197 156.385 69.3597 165.265 65.8397C173.185 62.7197 179.025 54.7197 179.505 46.3997C179.905 39.5197 176.785 28.7197 169.985 26.3197C167.505 25.4397 164.305 27.5197 164.225 27.5197C164.065 27.5997 163.905 27.5997 163.745 27.5997C163.585 27.5197 163.505 27.4397 163.425 27.2797C162.705 24.4797 156.225 23.5197 151.265 23.5197Z"
      fill="#231F20"
    />
    <path
      d="M165.185 77.0398L165.265 91.8398C165.265 91.8398 163.745 100.16 154.705 97.5998C149.345 95.9998 144.865 92.1598 142.705 86.9598C142.705 86.9598 141.985 55.6798 141.745 46.2398C152.705 46.0798 164.785 39.0398 164.785 39.0398C164.785 39.0398 168.545 44.7198 170.225 46.4798C171.505 47.8398 172.705 49.3598 173.585 50.9598C175.105 53.8398 175.025 57.3598 174.945 60.5598C174.945 64.3198 174.545 68.0798 173.825 71.7598C172.465 78.7998 165.185 77.0398 165.185 77.0398Z"
      fill="white"
    />
    <path
      d="M157.905 98.6398C156.785 98.6398 155.665 98.4798 154.545 98.1598C148.945 96.5598 144.465 92.5598 142.225 87.1998C142.225 87.1198 142.225 87.1198 142.225 87.0398C142.225 86.7198 141.505 55.6798 141.265 46.3198C141.265 46.1598 141.345 46.0798 141.425 45.9198C141.505 45.8398 141.665 45.7598 141.745 45.7598C152.465 45.5998 164.385 38.6398 164.545 38.5598C164.785 38.3998 165.105 38.4798 165.265 38.7198C165.265 38.7998 169.025 44.3998 170.625 46.0798C171.985 47.4398 173.105 48.9598 174.065 50.6398C175.665 53.6798 175.585 57.4398 175.505 60.4798C175.505 64.2398 175.105 67.9998 174.385 71.7598C174.065 73.6798 173.025 75.3598 171.345 76.4798C169.585 77.4398 167.585 77.8398 165.585 77.5198L165.665 91.7598C165.185 94.0798 163.905 96.0798 161.985 97.3598C160.865 98.2398 159.425 98.6398 157.905 98.6398ZM143.265 86.8798C145.425 91.8398 149.665 95.5998 154.865 97.1198C157.585 97.8398 159.745 97.6798 161.505 96.6398C163.105 95.4398 164.305 93.7598 164.705 91.7598L164.625 77.0398C164.625 76.8798 164.705 76.7198 164.785 76.6398C164.945 76.5598 165.105 76.4798 165.185 76.5598C165.185 76.5598 168.385 77.2798 170.785 75.7598C172.145 74.7998 173.105 73.3598 173.345 71.6798C174.065 67.9998 174.385 64.3198 174.465 60.5598C174.545 57.5198 174.625 53.9998 173.105 51.1998C172.225 49.5998 171.105 48.1598 169.825 46.7998C168.385 45.2798 165.505 41.0398 164.625 39.6798C162.385 40.8798 151.985 46.3998 142.225 46.7198C142.545 56.4798 143.185 85.1198 143.265 86.8798Z"
      fill="#231F20"
    />
    <path
      d="M165.105 84.3195C165.105 84.3195 159.105 84.5595 155.185 80.5595C151.265 76.5595 151.905 72.4795 151.905 72.4795C151.905 72.4795 158.385 76.8795 165.185 77.0395L165.105 84.3195Z"
      fill="#231F20"
    />
    <path
      d="M164.785 84.8799C163.505 84.8799 158.305 84.6399 154.785 80.9599C150.785 76.7999 151.345 72.6399 151.345 72.4799C151.345 72.3199 151.505 72.1599 151.665 72.0799C151.825 71.9999 151.985 71.9999 152.145 72.1599C152.225 72.1599 158.625 76.4799 165.185 76.5599C165.425 76.5599 165.665 76.7999 165.665 77.0399L165.585 84.3199C165.585 84.5599 165.345 84.7999 165.105 84.7999L164.785 84.8799ZM152.385 73.4399C152.545 75.9999 153.745 78.4799 155.585 80.2399C158.625 83.4399 163.185 83.8399 164.625 83.8399L164.705 77.5199C159.265 77.2799 154.225 74.5599 152.385 73.4399Z"
      fill="#231F20"
    />
    <path
      d="M159.344 53.3596C159.344 53.3596 164.144 60.4796 163.024 61.7596C161.904 63.0396 158.704 62.1596 158.704 62.1596"
      fill="white"
    />
    <path
      d="M160.785 62.9596C160.065 62.9596 159.265 62.8796 158.545 62.6396C158.305 62.5596 158.145 62.3196 158.225 61.9996C158.305 61.7596 158.545 61.5996 158.785 61.6796C159.505 61.8396 161.825 62.2396 162.625 61.4396C163.105 60.9596 161.345 57.2796 158.945 53.6796C158.785 53.5196 158.705 53.1996 158.945 52.9596C159.105 52.7996 159.425 52.7196 159.665 52.9596C159.745 53.0396 159.745 53.0396 159.745 53.1196C161.265 55.3596 164.625 60.7196 163.345 62.0796C162.625 62.7196 161.745 63.0396 160.785 62.9596Z"
      fill="#231F20"
    />
    <path
      d="M165.638 52.6346C166.08 52.6172 166.411 51.9232 166.378 51.0844C166.345 50.2456 165.96 49.5797 165.519 49.597C165.077 49.6144 164.746 50.3084 164.779 51.1472C164.812 51.986 165.197 52.6519 165.638 52.6346Z"
      fill="#231F20"
    />
    <path
      d="M152.643 50.7671C152.668 49.9279 152.331 49.2371 151.889 49.224C151.447 49.2109 151.069 49.8805 151.044 50.7196C151.019 51.5588 151.357 52.2496 151.799 52.2627C152.24 52.2759 152.619 51.6062 152.643 50.7671Z"
      fill="#231F20"
    />
    <path
      d="M144.864 55.9996C143.264 53.7596 140.864 49.7596 137.584 50.9596C136.704 51.2796 135.984 51.9196 135.344 52.6396C132.704 55.7596 133.104 59.9196 134.864 63.3596C136.384 66.3196 141.104 70.7996 144.304 67.0396"
      fill="white"
    />
    <path
      d="M141.425 68.9597C141.265 68.9597 141.105 68.9597 140.945 68.9597C137.985 68.6397 135.345 65.4397 134.385 63.5997C132.305 59.4397 132.465 55.1997 134.945 52.3197C135.585 51.5197 136.385 50.8797 137.345 50.4797C140.705 49.1997 143.265 52.7997 144.865 55.1197L145.265 55.6797C145.425 55.9197 145.345 56.2397 145.185 56.3997C144.945 56.5597 144.625 56.4797 144.465 56.3197L144.065 55.7597C142.545 53.5197 140.385 50.4797 137.745 51.4397C136.945 51.7597 136.225 52.2397 135.745 52.9597C133.585 55.5197 133.425 59.3597 135.345 63.1197C136.305 64.9597 138.625 67.6797 141.105 67.9197C142.225 68.0797 143.265 67.5997 143.985 66.7197C144.145 66.4797 144.465 66.4797 144.705 66.6397C144.945 66.7997 144.945 67.1197 144.785 67.3597C143.825 68.3997 142.625 68.9597 141.425 68.9597Z"
      fill="#231F20"
    />
    <path
      d="M159.905 69.9198C159.665 69.9198 159.345 69.9198 159.105 69.8398C154.385 69.2798 151.105 64.4798 150.945 64.2398C150.865 63.9998 150.945 63.6798 151.185 63.5998C151.345 63.5198 151.585 63.5998 151.745 63.6798C151.745 63.7598 154.945 68.3998 159.185 68.8798C161.265 69.1198 163.345 68.3198 165.265 66.4798C165.425 66.3198 165.745 66.2398 165.985 66.4798C166.145 66.6398 166.225 66.9598 165.985 67.1998C165.985 67.1998 165.985 67.1998 165.905 67.1998C164.385 68.8798 162.225 69.8398 159.905 69.9198Z"
      fill="#231F20"
    />
    <path
      d="M123.505 129.28C123.185 129.28 122.945 129.04 122.945 128.72V105.44C122.945 105.12 123.185 104.88 123.505 104.88C123.825 104.88 124.065 105.12 124.065 105.44V128.72C124.065 129.04 123.825 129.28 123.505 129.28Z"
      fill="#231F20"
    />
    <path
      d="M182.704 129.36C182.464 129.36 182.224 129.2 182.144 128.88L177.584 104.64C177.504 104.32 177.744 104.08 178.064 104C178.384 103.92 178.624 104.16 178.704 104.48L183.264 128.72C183.344 129.04 183.104 129.28 182.784 129.36H182.704Z"
      fill="#231F20"
    />
    <path
      d="M155.024 95.8398C155.024 95.8398 142.464 109.12 143.104 122.16L164.224 124.56C164.224 124.56 160.384 101.52 162.784 95.7598L155.024 95.8398Z"
      fill="white"
    />
    <path
      d="M164.225 125.12L143.025 122.72C142.785 122.72 142.545 122.48 142.545 122.16C141.905 109.04 154.065 95.9996 154.625 95.4396C154.705 95.3596 154.865 95.2796 155.025 95.2796L162.785 95.1196C162.945 95.1196 163.185 95.1996 163.265 95.3596C163.345 95.5196 163.425 95.6796 163.345 95.9196C161.025 101.52 164.785 124.24 164.865 124.48C164.945 124.8 164.705 125.04 164.385 125.12C164.305 125.12 164.225 125.12 164.225 125.12ZM143.665 121.6L163.585 123.92C162.945 120.08 160.305 102.48 161.985 96.3196L155.265 96.3996C153.825 97.9996 143.345 109.92 143.665 121.6Z"
      fill="#231F20"
    />
    <path
      d="M153.665 110.72H153.584C153.264 110.72 153.104 110.4 153.104 110.08C153.744 105.68 155.424 101.52 157.984 97.8397C158.144 97.5997 158.544 97.5197 158.784 97.7597C159.024 97.9197 159.105 98.3197 158.865 98.5597C156.385 102.08 154.864 106.08 154.224 110.24C154.144 110.56 153.905 110.72 153.665 110.72Z"
      fill="#D1D3D4"
    />
    <path
      d="M166.224 100.56C166.144 100.56 166.064 100.56 165.984 100.56C165.664 100.4 165.584 100.08 165.664 99.8395C166.864 97.2795 167.744 94.6395 168.464 91.9995C169.184 89.3595 169.104 86.5595 168.304 83.9195C168.224 83.5995 168.384 83.2795 168.624 83.1995C168.944 83.1195 169.264 83.2795 169.344 83.5195C170.224 86.3195 170.224 89.3595 169.504 92.1595C168.784 94.9595 167.824 97.5995 166.624 100.24C166.624 100.48 166.384 100.56 166.224 100.56Z"
      fill="#D1D3D4"
    />
    <path
      d="M153.825 55.5997H147.505C145.265 55.5997 143.505 53.8397 143.505 51.5997V47.2797C143.505 46.9597 143.745 46.7197 144.065 46.7197H157.265C157.585 46.7197 157.825 46.9597 157.825 47.2797V51.5997C157.825 53.7597 156.065 55.5997 153.825 55.5997ZM144.625 47.8397V51.5997C144.625 53.1997 145.905 54.4797 147.505 54.4797H153.825C155.425 54.4797 156.705 53.1997 156.705 51.5997V47.8397H144.625Z"
      fill="#231F20"
    />
    <path
      d="M169.505 55.5997H163.185C160.945 55.5997 159.185 53.8397 159.185 51.5997V47.2797C159.185 46.9597 159.425 46.7197 159.745 46.7197H172.945C173.265 46.7197 173.505 46.9597 173.505 47.2797V51.5997C173.505 53.7597 171.745 55.5997 169.505 55.5997ZM160.305 47.8397V51.5997C160.305 53.1997 161.585 54.4797 163.185 54.4797H169.505C171.105 54.4797 172.385 53.1997 172.385 51.5997V47.8397H160.305Z"
      fill="#231F20"
    />
    <path
      d="M163.185 47.8397H156.305C155.985 47.8397 155.745 47.5997 155.745 47.2797C155.745 46.9597 155.985 46.7197 156.305 46.7197H163.185C163.505 46.7197 163.745 46.9597 163.745 47.2797C163.745 47.5997 163.505 47.8397 163.185 47.8397Z"
      fill="#231F20"
    />
    <line x1="30" y1="129.5" x2="394" y2="129.5" stroke="black" />
    <path
      d="M57.5045 79.1997C57.5045 79.1997 37.9045 79.8397 30.2245 93.9997C22.5445 108.16 22.2245 128.8 22.2245 128.8H111.584C111.584 128.8 101.584 94.7997 88.1445 86.7197C74.7045 78.6397 57.5045 79.1997 57.5045 79.1997Z"
      fill="#27AE60"
    />
    <path
      d="M35.7445 123.44C35.6645 123.44 35.5845 123.44 35.5045 123.36C35.2645 123.2 35.1045 122.88 35.2645 122.64C35.6645 121.84 40.1445 105.68 43.0245 95.04C43.1045 94.72 43.4245 94.56 43.7445 94.64C44.0645 94.72 44.2245 95.04 44.1445 95.28C43.3445 98 36.7045 122.16 36.3045 123.04C36.2245 123.28 35.9845 123.44 35.7445 123.44Z"
      fill="#231F20"
    />
    <path
      d="M68.0645 28.9599C68.0645 28.9599 73.0245 16.3999 60.3045 13.1999C47.5845 9.99989 35.9045 18.3999 37.3445 29.5999C38.7845 40.7999 47.3445 41.0399 47.3445 41.0399L68.0645 28.9599Z"
      fill="white"
    />
    <path
      d="M47.3444 41.5999C47.2644 41.5999 38.3044 41.2799 36.7844 29.6799C36.0644 24.7999 37.9044 19.9199 41.6644 16.7199C46.4644 12.4799 53.4244 10.9599 60.3844 12.6399C64.2244 13.5999 66.9444 15.5199 68.3844 18.3199C70.8644 23.1999 68.7044 28.8799 68.6244 29.1999C68.5444 29.2799 68.4644 29.4399 68.3844 29.5199L47.6644 41.5999C47.5844 41.5199 47.4244 41.5999 47.3444 41.5999ZM54.7044 13.1199C50.1444 13.0399 45.8244 14.6399 42.3844 17.5999C38.9444 20.5599 37.1844 25.1199 37.8244 29.5999C39.1844 39.5199 46.0644 40.3999 47.1844 40.4799L67.5844 28.5599C67.9044 27.5999 69.4244 22.7999 67.3444 18.7999C66.0644 16.3199 63.5844 14.6399 60.0644 13.7599C58.3844 13.2799 56.5444 13.1199 54.7044 13.1199Z"
      fill="#231F20"
    />
    <path
      d="M49.4245 30.2399C51.3445 29.0399 53.4245 28.2399 55.5845 27.8399C61.5845 26.6399 68.4645 27.6799 72.6245 32.1599C70.6245 30.6399 67.9045 31.8399 65.5045 32.6399C63.4245 33.3599 61.2645 33.6799 59.1845 34.3199C56.3845 35.1999 53.8245 36.5599 51.5045 38.3199C50.1445 39.3599 46.9445 43.9199 45.3445 43.8399C42.3845 43.5999 43.6645 38.4799 44.2245 36.9599C45.2645 34.1599 47.0245 31.8399 49.4245 30.2399Z"
      fill="#D1D3D4"
    />
    <path
      d="M45.5844 44.3998H45.5044C44.7844 44.3998 44.0644 43.9998 43.6644 43.3598C42.3044 41.3598 43.7444 37.1999 43.9044 36.7199C44.8644 33.8399 46.7044 31.4398 49.1844 29.7598C51.1044 28.5598 53.3444 27.6799 55.5844 27.2799C61.0244 26.1599 68.4644 26.7998 73.1844 31.7598C73.4244 31.9998 73.4244 32.3198 73.1844 32.5598C72.9444 32.7998 72.6244 32.7998 72.3844 32.5598C70.7844 31.3598 68.5444 32.1599 66.3844 32.8799L65.7444 33.1199C64.6244 33.5199 63.4244 33.8399 62.3044 34.0799C61.3444 34.3199 60.3844 34.5598 59.5044 34.7998C56.7844 35.6798 54.2244 36.9599 51.9844 38.7199C51.3444 39.2799 50.7044 39.9198 50.1444 40.6398C48.2244 42.8798 46.7844 44.3998 45.5844 44.3998ZM60.2244 27.9998C58.7044 27.9998 57.1844 28.1599 55.7444 28.4799C53.6644 28.8799 51.5844 29.5998 49.7444 30.7998C47.5044 32.3198 45.7444 34.5598 44.9444 37.1998C44.3844 38.7998 43.8244 41.6798 44.6244 42.7998C44.8644 43.1198 45.1844 43.3598 45.5844 43.3598C46.3044 43.3598 48.2244 41.2799 49.3444 40.0799C49.9844 39.2799 50.6244 38.6398 51.3444 37.9998C53.6644 36.1598 56.3844 34.7999 59.1844 33.9199C60.1444 33.5999 61.1044 33.3599 62.0644 33.1199C63.1844 32.8799 64.3044 32.5598 65.4244 32.1598L66.0644 31.9998C67.4244 31.4398 68.8644 31.1198 70.3844 30.9598C67.2644 28.8798 63.7444 27.9198 60.2244 27.9998Z"
      fill="#231F20"
    />
    <path
      d="M80.8645 33.04C74.0645 29.28 64.9445 28.64 57.5045 30.88C50.0645 33.12 45.3445 39.2 43.5845 46.72C42.3845 51.92 41.4245 59.84 45.6645 63.68C50.3045 67.92 55.8245 70.16 62.0645 71.2C68.4645 72.16 76.3845 72.08 81.6645 67.92C86.9445 63.76 90.4645 56.4 90.6245 49.92C90.9445 41.84 86.7845 36.32 80.8645 33.04Z"
      fill="white"
    />
    <path
      d="M68.3845 72.2399C66.2245 72.2399 64.0645 72.0799 61.9845 71.7599C55.1845 70.7199 49.7445 68.2399 45.2645 64.1599C40.9445 60.2399 41.6645 52.4799 43.0245 46.6399C44.9445 38.4799 50.1445 32.4799 57.3445 30.3999C65.1045 28.0799 74.4645 28.9599 81.1045 32.6399C87.8245 36.3199 91.4245 42.4799 91.1845 49.9199C90.9445 56.7199 87.1845 64.3199 81.9845 68.3999C78.1445 71.4399 72.9445 72.2399 68.3845 72.2399ZM66.4645 30.1599C63.5045 30.1599 60.5445 30.5599 57.6645 31.3599C50.7845 33.4399 45.9845 38.8799 44.1445 46.7999C43.1045 51.3599 41.9045 59.5199 46.0645 63.1999C50.3845 67.1199 55.6645 69.5199 62.2245 70.5599C66.9445 71.2799 75.6645 71.8399 81.4245 67.3599C86.3845 63.5199 89.9845 56.2399 90.1445 49.7599C90.4645 40.7999 85.1045 35.9199 80.6245 33.4399C76.2245 31.2799 71.3445 30.0799 66.4645 30.1599Z"
      fill="#231F20"
    />
    <path
      d="M74.7045 78.48L75.6645 92C75.6645 92 74.3845 98.32 65.1845 96.8C55.9845 95.28 54.1445 89.2 54.1445 89.2L53.2645 63.92C53.2645 63.92 66.7845 55.28 63.6645 40.96C63.6645 40.96 70.0645 48.8 83.2645 46C83.2645 46 84.4645 62.08 83.9845 69.12C83.5045 76.32 74.7045 78.48 74.7045 78.48Z"
      fill="white"
    />
    <path
      d="M67.7445 97.5999C66.8645 97.5999 65.9845 97.5199 65.1045 97.3599C55.6645 95.8399 53.7445 89.6799 53.6645 89.4399C53.6645 89.3599 53.6645 89.3599 53.6645 89.2799L52.7845 63.9999C52.7845 63.8399 52.8645 63.5999 53.0245 63.5199C53.1845 63.4399 66.2245 54.8799 63.1845 41.1199C63.1045 40.7999 63.3445 40.4799 63.5845 40.4799C63.8245 40.3999 63.9845 40.4799 64.1445 40.6399C64.2245 40.7199 70.4645 48.1599 83.2645 45.5199C83.4245 45.5199 83.5845 45.5199 83.7445 45.5999C83.9045 45.6799 83.9845 45.8399 83.9845 45.9999C83.9845 46.1599 85.1845 62.2399 84.7045 69.2799C84.2245 75.7599 77.3445 78.3199 75.4245 78.8799L76.3845 91.9199C76.3845 91.9999 76.3845 91.9999 76.3845 92.0799C76.2245 92.1599 75.0245 97.5999 67.7445 97.5999ZM54.7045 89.1999C54.9445 89.8399 56.9445 94.9599 65.2645 96.3199C73.3445 97.5999 74.9445 92.6399 75.1045 91.9999L74.1445 78.5599C74.1445 78.3199 74.3045 78.0799 74.5445 77.9999C74.6245 77.9999 82.9445 75.8399 83.4245 69.2799C83.9045 63.1199 83.0245 49.8399 82.7845 46.7999C72.9445 48.6399 66.9445 44.7999 64.5445 42.7199C66.1445 55.1199 55.6645 63.1199 53.8245 64.3999L54.7045 89.1999Z"
      fill="#231F20"
    />
    <path
      d="M57.5044 62.96C57.1044 61.76 56.3044 60.72 55.2644 60C54.2244 59.36 53.1044 58.88 51.9844 58.64C51.3444 58.4 50.7044 58.4 50.0644 58.48C49.5844 58.56 49.1844 58.8 48.8644 59.04C47.9044 59.6 47.1844 60.48 46.8644 61.52C46.7044 62.4 46.7044 63.28 46.9444 64.08C47.1044 65.04 47.4244 65.92 47.8244 66.8C49.6644 70.32 53.9844 71.68 57.5044 69.84C57.5044 69.84 57.5844 69.84 57.5844 69.76"
      fill="white"
    />
    <path
      d="M54.1445 71.12C53.4245 71.12 52.6245 71.04 51.9045 70.8C49.9045 70.16 48.3045 68.8 47.3445 66.96C46.8645 66.08 46.5445 65.12 46.3845 64.16C46.1445 63.2 46.0645 62.24 46.3045 61.28C46.7045 60.08 47.5045 59.12 48.5445 58.48C48.9445 58.16 49.5045 57.92 49.9845 57.84C50.7045 57.76 51.4245 57.84 52.1445 58C53.3445 58.24 54.5445 58.72 55.6645 59.44C56.7845 60.24 57.6645 61.36 58.1445 62.72C58.2245 63.04 58.0645 63.36 57.8245 63.44C57.5045 63.52 57.1845 63.36 57.1045 63.12C56.7045 62 56.0645 61.04 55.1045 60.4C54.1445 59.84 53.1045 59.36 51.9845 59.12C51.4245 58.96 50.8645 58.88 50.3045 58.96C49.9045 59.04 49.5845 59.2 49.2645 59.44C48.4645 59.92 47.8245 60.72 47.5045 61.6C47.3445 62.4 47.4245 63.12 47.5845 63.92C47.7445 64.8 48.0645 65.68 48.4645 66.48C50.1445 69.68 54.1445 70.96 57.4245 69.28H57.5045C57.7445 69.12 58.1445 69.2 58.2245 69.52C58.3845 69.76 58.3045 70.16 58.0645 70.32C56.7045 70.88 55.4245 71.12 54.1445 71.12Z"
      fill="#231F20"
    />
    <path
      d="M72.4645 56.72C72.4645 56.72 77.9845 63.2 77.0245 64.56C76.0645 65.92 72.7845 65.44 72.7845 65.44"
      fill="white"
    />
    <path
      d="M73.9846 66.0799C73.5846 66.0799 73.1046 66.0799 72.7046 65.9999C72.3846 65.9999 72.1446 65.7599 72.1446 65.4399C72.1446 65.1199 72.3846 64.8799 72.7046 64.8799H72.7846C73.5046 64.9599 75.8246 65.0399 76.4646 64.1599C76.7846 63.5199 74.6246 60.1599 71.9046 57.0399C71.6646 56.7999 71.7446 56.4799 71.9846 56.2399C72.2246 55.9999 72.5446 56.0799 72.7846 56.3199C74.9446 58.8799 78.4646 63.3599 77.3446 64.8799C76.7046 65.9199 75.1846 66.0799 73.9846 66.0799Z"
      fill="#231F20"
    />
    <path
      d="M76.6245 69.2L65.9845 65.52C65.9845 65.52 63.9845 69.44 67.8245 73.2C71.9845 77.28 76.6245 69.2 76.6245 69.2Z"
      fill="white"
    />
    <path
      d="M70.3845 74.9598C69.2645 74.9598 68.2245 74.4798 67.5045 73.6798C63.4245 69.6798 65.5845 65.3598 65.5845 65.3598C65.7445 65.1198 65.9845 64.9599 66.3045 65.0399L76.9445 68.7199C77.1045 68.7999 77.2645 68.8799 77.2645 69.0399C77.3445 69.1999 77.3445 69.3599 77.2645 69.5199C77.1845 69.6799 74.3845 74.4798 71.0245 74.9598C70.7045 74.8798 70.5445 74.9598 70.3845 74.9598ZM66.3845 66.2399C66.0645 67.2799 65.5045 70.0799 68.3045 72.7999C68.9445 73.5199 69.8245 73.8398 70.7845 73.7598C72.9445 73.4398 75.0245 70.7199 75.8245 69.4399L66.3845 66.2399Z"
      fill="#231F20"
    />
    <path
      d="M69.3445 55.3599C69.2645 55.3599 69.1045 55.3599 69.0245 55.2799C66.2245 53.5999 64.5445 54.2399 64.5445 54.2399C64.2245 54.3199 63.9045 54.1599 63.8245 53.9199C63.7445 53.6799 63.8245 53.3599 64.1445 53.1999C64.2245 53.1999 66.3845 52.3199 69.6645 54.3199C69.9045 54.4799 69.9845 54.7999 69.8245 55.1199C69.7445 55.2799 69.5845 55.3599 69.3445 55.3599Z"
      fill="#231F20"
    />
    <path
      d="M76.2245 55.4399C76.0645 55.4399 75.9045 55.3599 75.8245 55.2799C75.5845 55.0399 75.5845 54.7199 75.8245 54.4799C75.9045 54.3999 76.0645 54.3199 76.2245 54.3199L76.7045 54.0799C77.7445 53.4399 79.9045 52.2399 82.0645 53.1199C82.3845 53.2799 82.4645 53.5999 82.3845 53.8399C82.3045 54.1599 81.9845 54.2398 81.6645 54.1598C80.0645 53.5198 78.2245 54.4799 77.2645 55.0399C76.9445 55.1999 76.6245 55.3599 76.2245 55.4399Z"
      fill="#231F20"
    />
    <path
      d="M74.7044 78.48C74.7044 78.48 67.6644 78.8 64.3044 77.04C64.3044 77.04 67.4244 84.96 75.1044 86.56L74.7044 78.48Z"
      fill="#231F20"
    />
    <path
      d="M75.1845 87.1999H75.0245C67.1045 85.5199 63.9045 77.6799 63.8245 77.2799C63.7445 77.0399 63.8245 76.7999 63.9845 76.6399C64.1445 76.4799 64.3845 76.4799 64.6245 76.5599C67.8245 78.2399 74.6245 77.9199 74.7045 77.9199C75.0245 77.9199 75.2645 78.1599 75.2645 78.4799L75.6645 86.6399C75.6645 86.7999 75.5845 86.9599 75.5045 87.1199C75.4245 87.1999 75.2645 87.1999 75.1845 87.1999ZM65.5045 78.1599C66.7045 80.3199 69.5845 84.5599 74.6245 85.9199L74.2245 79.1199C72.7045 79.1199 68.5445 79.1199 65.5045 78.1599Z"
      fill="#231F20"
    />
    <path
      d="M39.9845 128C42.0645 120.32 35.0245 106.88 33.0245 100.16C31.1845 93.9199 22.6244 77.5999 24.2244 71.1999C24.2244 71.1999 28.1444 55.6799 24.3845 57.9999C22.3045 59.2799 20.7844 65.6799 20.7844 65.6799C20.7844 65.6799 18.4645 51.5999 14.7845 50.1599C11.1045 48.7199 3.82445 57.9999 4.94445 61.9199C8.54445 74.8799 9.90445 88.5599 11.5845 101.84C13.0245 113.76 12.7845 132.8 29.1045 133.68C35.8245 134 38.9445 131.68 39.9845 128Z"
      fill="white"
    />
    <path
      d="M30.3845 134.24C29.9845 134.24 29.5045 134.24 29.0245 134.24C14.1445 133.44 12.5445 117.76 11.4245 106.32C11.2645 104.8 11.1045 103.28 10.9445 101.92L10.3845 97.36C9.0245 85.6 7.5845 73.52 4.3845 62.08C3.5845 59.36 6.46449 54.48 9.5045 51.68C11.5845 49.76 13.5045 49.12 14.9445 49.6C17.9845 50.8 19.9845 58.96 20.8645 63.2C21.5045 61.12 22.6245 58.4 24.0645 57.52C24.5445 57.12 25.1845 57.12 25.7445 57.44C27.7445 58.88 25.5045 68.48 24.7045 71.36C23.5845 75.68 27.5845 85.36 30.5445 92.4C31.8245 95.52 32.9445 98.16 33.5045 100C34.0645 101.84 34.9445 104 35.8245 106.32C38.6245 113.44 42.0645 122.32 40.4645 128.08C39.4245 132.24 35.9845 134.24 30.3845 134.24ZM13.9845 50.56C13.0245 50.56 11.6645 51.2 10.3045 52.48C7.18449 55.28 4.9445 59.68 5.5045 61.76C8.70449 73.28 10.1445 85.44 11.5845 97.2L12.1445 101.76C12.3045 103.12 12.4645 104.64 12.6245 106.16C13.7445 117.76 15.1845 132.32 29.1845 133.04C35.0245 133.36 38.3845 131.6 39.4245 127.84C40.9445 122.32 37.5045 113.68 34.7845 106.72C33.8245 104.32 32.9445 102.08 32.3845 100.32C31.8245 98.56 30.7845 95.92 29.5045 92.88C26.3845 85.28 22.3845 75.84 23.5845 71.12C25.0245 65.52 25.9045 59.04 25.0245 58.4C25.0245 58.4 24.8645 58.4 24.6245 58.56C23.0245 59.52 21.6645 64.16 21.3445 65.92C21.2645 66.16 21.0245 66.4 20.7845 66.4C20.5445 66.4 20.3045 66.16 20.2245 65.92C19.5845 62.08 17.3445 51.92 14.6245 50.8C14.3845 50.64 14.2245 50.56 13.9845 50.56Z"
      fill="#231F20"
    />
    <path
      d="M12.7045 72.3998H12.5445C12.2245 72.3198 12.0645 71.9998 12.1445 71.7598C13.1845 67.4398 20.2245 65.2798 20.5445 65.1998C20.8645 65.1198 21.1845 65.2798 21.2645 65.5998C21.3445 65.9198 21.1845 66.2398 20.8645 66.3198C20.7845 66.3198 14.1445 68.3998 13.1845 72.0798C13.1845 72.2398 13.0245 72.3998 12.7045 72.3998Z"
      fill="#231F20"
    />
    <path
      d="M90.7045 129.28C90.4645 129.28 90.2245 129.04 90.1445 128.8L85.3445 97.1199C85.2645 96.7999 85.4245 96.4799 85.7445 96.4799C86.0645 96.3999 86.3845 96.5599 86.3845 96.8799V96.9599L91.1845 128.64C91.2645 128.96 91.0245 129.2 90.7045 129.28Z"
      fill="#231F20"
    />
    <path
      d="M51.4245 109.52C51.3445 109.52 51.2645 109.52 51.1845 109.52C50.8645 109.44 50.7845 109.12 50.8645 108.8L56.8645 93.5998C56.9445 93.2798 57.2645 93.1998 57.5845 93.2798C57.9045 93.3598 57.9846 93.6798 57.9046 93.9998L51.9045 109.2C51.8245 109.36 51.5845 109.52 51.4245 109.52Z"
      fill="#231F20"
    />
    <path
      d="M58.8644 99.2798C58.7844 99.2798 58.7844 99.2798 58.7044 99.2798L52.2244 97.0398C51.9044 96.9598 51.7444 96.6398 51.9044 96.3198C51.9844 95.9998 52.3044 95.8398 52.6244 95.9998L59.1044 98.1598C59.4244 98.2398 59.5844 98.5598 59.4244 98.8798C59.2644 99.1198 59.1044 99.1998 58.8644 99.2798Z"
      fill="#231F20"
    />
    <path
      d="M57.4245 104.56C57.3445 104.56 57.2645 104.56 57.1845 104.56L50.5445 101.92C50.2245 101.76 50.1445 101.44 50.2245 101.2C50.3045 100.88 50.6245 100.8 50.9445 100.88L57.5845 103.52C57.9045 103.6 57.9845 104 57.9045 104.24C57.8245 104.4 57.6645 104.56 57.4245 104.56Z"
      fill="#231F20"
    />
    <path
      d="M198.064 100.72C200.464 93.1195 204.784 90.3995 222.944 80.7195C241.104 71.0395 267.744 78.3195 277.344 84.0795C286.944 89.8395 293.984 98.3195 299.344 104.96C304.704 111.6 304.704 128.559 304.704 128.559H195.984C195.984 128.559 195.744 108.32 198.064 100.72Z"
      fill="#27AE60"
    />
    <path
      d="M276.784 129.12C276.464 129.12 276.224 128.88 276.224 128.64L274.064 103.12C274.064 102.8 274.304 102.559 274.544 102.479C274.864 102.479 275.104 102.719 275.184 102.959L277.344 128.479C277.344 128.799 277.104 129.04 276.784 129.12Z"
      fill="#231F20"
    />
    <path
      d="M246.625 20.9596C259.585 22.0796 265.665 28.3196 269.105 55.3596C271.825 76.6396 270.065 74.9597 283.905 87.9997C283.905 87.9997 271.265 105.28 245.665 105.28C220.065 105.28 202.385 91.9997 202.385 91.9997C202.385 91.9997 221.745 80.1596 221.345 68.5596C220.145 28.1596 233.665 19.8396 246.625 20.9596Z"
      fill="white"
    />
    <path
      d="M245.744 105.84C230.224 105.76 215.024 101.04 202.144 92.3996C201.984 92.3196 201.904 92.0796 201.904 91.9196C201.904 91.7596 201.984 91.5996 202.144 91.4396C202.304 91.3596 221.184 79.6796 220.864 68.5596C220.224 47.0396 223.664 32.4796 231.184 25.3596C235.264 21.5196 240.464 19.8396 246.624 20.3996C260.944 21.5996 266.304 29.7596 269.584 55.2796C269.904 57.6796 270.144 59.7596 270.384 61.5996C272.144 76.0796 272.144 76.0796 284.224 87.4396L284.304 87.5196C284.544 87.6796 284.544 87.9996 284.384 88.2396C284.304 88.4796 271.264 105.84 245.744 105.84ZM203.504 91.9996C216.064 100.16 230.784 104.64 245.744 104.72C268.544 104.72 281.104 90.5596 283.184 87.9996C271.104 76.6396 271.104 76.5596 269.344 61.7596C269.104 59.9196 268.864 57.8396 268.544 55.4396C265.024 27.9196 258.624 22.5596 246.544 21.5196C240.624 21.0396 235.744 22.5596 231.904 26.2396C224.624 33.1196 221.264 47.3596 221.904 68.5596C222.304 79.1996 207.024 89.6796 203.504 91.9996Z"
      fill="#231F20"
    />
    <path
      d="M244.625 72.5596C252.622 72.5596 259.105 63.283 259.105 51.8396C259.105 40.3963 252.622 31.1196 244.625 31.1196C236.627 31.1196 230.145 40.3963 230.145 51.8396C230.145 63.283 236.627 72.5596 244.625 72.5596Z"
      fill="white"
    />
    <path
      d="M244.625 73.1196C231.345 73.1196 229.505 62.8796 229.505 51.8396C229.505 40.3996 229.505 30.5596 244.625 30.5596C259.745 30.5596 259.665 40.3996 259.665 51.8396C259.745 62.8796 257.905 73.1196 244.625 73.1196ZM244.625 31.6796C230.625 31.6796 230.625 40.1596 230.625 51.8396C230.625 63.9196 233.025 71.9996 244.625 71.9996C256.225 71.9996 258.545 63.9196 258.545 51.8396C258.625 40.1596 258.625 31.6796 244.625 31.6796Z"
      fill="#231F20"
    />
    <path
      d="M241.985 48.6396C241.985 48.6396 236.465 55.1197 237.425 56.4797C238.385 57.8397 241.585 57.3596 241.585 57.3596"
      fill="white"
    />
    <path
      d="M240.465 57.9997C239.345 57.9997 237.745 57.8397 237.025 56.7997C235.985 55.2797 239.425 50.7997 241.585 48.3197C241.745 48.0797 242.145 48.0797 242.385 48.2397C242.625 48.3997 242.625 48.7997 242.465 49.0397C239.745 52.2397 237.665 55.5997 237.985 56.1597C238.625 57.0397 240.865 56.9597 241.665 56.7997C241.985 56.7997 242.225 56.9597 242.305 57.2797C242.385 57.5997 242.145 57.8397 241.825 57.9197C241.265 57.9997 240.865 57.9997 240.465 57.9997Z"
      fill="#231F20"
    />
    <path
      d="M236.078 48.4024C236.74 48.3987 237.273 47.6435 237.268 46.7157C237.263 45.7878 236.722 45.0387 236.059 45.0424C235.396 45.0461 234.863 45.8012 234.868 46.7291C234.873 47.6569 235.415 48.4061 236.078 48.4024Z"
      fill="#231F20"
    />
    <path
      d="M248.478 48.4024C249.14 48.3987 249.673 47.6435 249.668 46.7157C249.663 45.7878 249.122 45.0387 248.459 45.0424C247.796 45.0461 247.263 45.8012 247.268 46.7291C247.273 47.6569 247.815 48.4061 248.478 48.4024Z"
      fill="#231F20"
    />
    <path
      d="M237.824 61.1197L248.464 57.4397C248.464 57.4397 250.464 61.3597 246.624 65.1197C242.464 69.1997 237.824 61.1197 237.824 61.1197Z"
      fill="white"
    />
    <path
      d="M244.065 66.8797C243.905 66.8797 243.745 66.8797 243.585 66.8797C240.225 66.3997 237.425 61.6797 237.345 61.4397C237.185 61.1997 237.265 60.7997 237.585 60.6397H237.665L248.305 56.9597C248.545 56.8797 248.865 56.9597 249.025 57.1997C249.025 57.2797 251.105 61.5197 247.105 65.5197C246.225 66.3997 245.185 66.7997 244.065 66.8797ZM238.625 61.3597C239.425 62.5597 241.505 65.3597 243.745 65.6797C244.705 65.7597 245.585 65.4397 246.225 64.7197C249.025 61.9997 248.465 59.1997 248.145 58.1597L238.625 61.3597Z"
      fill="#231F20"
    />
    <path
      d="M221.344 129.12C221.024 129.12 220.784 128.88 220.784 128.56V106.32C220.784 106 221.024 105.76 221.344 105.76C221.664 105.76 221.904 106 221.904 106.32V128.56C221.904 128.88 221.664 129.12 221.344 129.12Z"
      fill="#231F20"
    />
    <path
      d="M246.064 85.9196C241.024 85.8396 235.984 85.1196 231.104 83.7596C230.784 83.6796 230.624 83.3596 230.704 83.0396C230.784 82.7196 231.104 82.5596 231.344 82.6396C244.064 86.0796 252.704 84.6396 257.744 82.7996C263.184 80.7996 265.584 77.9996 265.664 77.9996C265.904 77.7596 266.224 77.7596 266.464 77.9196C266.704 78.0796 266.704 78.4796 266.544 78.7196C266.144 79.1196 260.224 85.9196 246.064 85.9196Z"
      fill="#D1D3D4"
    />
    <path
      d="M195.984 129.12C195.744 129.12 195.504 128.96 195.424 128.64C192.624 112.48 199.024 97.0396 199.104 96.8796C199.264 96.5596 199.584 96.4796 199.824 96.5596C200.144 96.7196 200.224 97.0396 200.144 97.2796C200.064 97.4396 193.824 112.64 196.544 128.48C196.624 128.8 196.384 129.04 196.144 129.12H195.984Z"
      fill="#231F20"
    />
    <path
      d="M301.984 24.88C301.984 24.88 312.864 3.68 314.704 2.96C316.544 2.24 321.904 4.40001 321.504 5.52001C321.104 6.64001 318.865 16.8 318.865 16.8C318.865 16.8 324.705 13.04 325.345 14.56C325.985 16.08 318.784 23.68 318.784 23.68L309.024 39.92L301.984 24.88Z"
      fill="#D1D3D4"
    />
    <path
      d="M309.024 40.48C308.784 40.48 308.624 40.32 308.544 40.16L301.424 25.12C301.344 24.96 301.344 24.8 301.424 24.64C303.984 19.6 312.544 3.20003 314.464 2.40003C316.224 1.68003 320.304 3.12003 321.584 4.40003C321.984 4.72003 322.144 5.20004 322.064 5.68004C321.824 6.40004 320.464 12.16 319.744 15.6C321.424 14.64 323.984 13.28 325.184 13.68C325.504 13.76 325.744 14 325.904 14.32C326.544 15.92 322.064 21.12 319.264 24.08L309.504 40.24C309.424 40.4 309.264 40.48 309.024 40.48ZM302.624 24.88L309.104 38.64L318.304 23.36L318.384 23.28C321.584 19.84 324.944 15.6 324.784 14.72C324.304 14.48 321.664 15.68 319.184 17.28C319.024 17.44 318.704 17.44 318.544 17.28C318.384 17.12 318.224 16.88 318.304 16.64C318.384 16.24 320.464 6.80003 320.944 5.36003C320.464 4.88003 319.904 4.48003 319.184 4.16003C317.504 3.44003 315.664 3.04003 314.864 3.36003C313.744 4.16003 307.744 14.96 302.624 24.88Z"
      fill="#231F20"
    />
    <path
      d="M302.784 58.4001L314.624 31.7601L301.424 20.5601C301.424 20.5601 268.944 62.4001 282.544 73.1201L313.584 103.52L342.064 83.5201L302.784 58.4001Z"
      fill="#27AE60"
    />
    <path
      d="M339.824 40C339.824 40 341.024 33.92 347.584 29.68C354.144 25.44 372.384 28.24 375.664 35.68C378.944 43.12 369.264 62.56 369.264 62.56L338.144 70.96L339.824 40Z"
      fill="white"
    />
    <path
      d="M338.144 71.4401C337.824 71.4401 337.584 71.2001 337.584 70.8801C337.584 70.8801 337.584 70.8801 337.584 70.8001L339.264 39.9201V39.8401C339.344 39.6001 340.544 33.4401 347.344 29.1201C351.264 26.6401 359.184 26.3201 366.304 28.4801C371.424 30.0001 374.944 32.4801 376.224 35.3601C379.584 42.9601 370.224 61.9201 369.824 62.7201C369.744 62.8801 369.584 62.9601 369.424 63.0401L338.304 71.4401C338.224 71.4401 338.224 71.4401 338.144 71.4401ZM340.384 40.0801L338.784 70.1601L368.864 62.0801C369.904 59.8401 378.144 42.6401 375.104 35.9201C373.904 33.3601 370.624 31.0401 365.904 29.6801C359.184 27.6801 351.504 27.9201 347.904 30.2401C344.224 32.4001 341.584 35.9201 340.384 40.0801Z"
      fill="#231F20"
    />
    <path
      d="M367.744 128.56C369.984 111.84 371.424 95.3599 369.584 92.2399C363.264 81.4399 334.704 88.0799 329.424 90.4799C327.344 91.4399 328.624 108.32 325.264 128.64H367.744V128.56Z"
      fill="#231F20"
    />
    <path
      d="M394.624 129.28C394.624 129.28 400.384 100.8 391.664 92.3201C382.944 83.8401 362.064 85.1201 362.064 85.1201C362.064 85.1201 329.024 80.5601 318.864 85.7601C314.704 87.9201 309.424 106.48 304.384 128.48H332.944L340.784 90.5601L366.384 91.3601L357.984 128.56L394.624 129.28Z"
      fill="#27AE60"
    />
    <path
      d="M343.104 75.7601L340.864 90.6401C340.864 90.6401 341.904 100.48 351.824 99.2801C357.824 98.4801 363.104 95.0401 366.224 89.9201L369.264 62.5601C369.264 62.5601 362.544 57.9201 366.384 42.5601C357.424 43.0401 348.464 42.1601 339.744 40.0801C339.744 40.0801 334.464 59.6001 334.784 67.2801C335.104 74.9601 343.104 75.7601 343.104 75.7601Z"
      fill="#D1D3D4"
    />
    <path
      d="M350.224 99.9201C348.064 100 345.904 99.2801 344.224 98.0001C340.864 95.3601 340.384 90.8801 340.384 90.7201C340.384 90.6401 340.384 90.6401 340.384 90.5601L342.544 76.2401C340.624 75.8401 334.624 74.0001 334.304 67.2001C333.984 59.5201 339.104 40.6401 339.344 39.8401C339.424 39.5201 339.744 39.3601 340.064 39.4401C348.704 41.5201 357.584 42.3201 366.464 41.9201C366.624 41.9201 366.864 42.0001 366.944 42.0801C367.104 42.2401 367.104 42.4001 367.024 42.5601C363.264 57.2801 369.584 61.9201 369.664 62.0001C369.824 62.1601 369.904 62.3201 369.904 62.5601L366.864 89.9201C366.864 90.0001 366.864 90.0801 366.784 90.1601C363.584 95.4401 358.064 99.0401 351.984 99.8401C351.344 99.9201 350.784 99.9201 350.224 99.9201ZM341.504 90.6401C341.584 91.2001 342.144 94.8801 344.944 97.0401C346.704 98.4801 349.024 98.9601 351.824 98.6401C357.584 97.8401 362.624 94.5601 365.744 89.6801L368.704 62.7201C367.424 61.6001 362.544 56.4801 365.744 43.0401C357.184 43.3601 348.624 42.5601 340.304 40.6401C339.424 43.8401 335.184 60.3201 335.504 67.1201C335.824 74.1601 342.944 75.1201 343.264 75.1201C343.424 75.1201 343.584 75.2001 343.664 75.3601C343.744 75.5201 343.824 75.6801 343.744 75.7601L341.504 90.6401Z"
      fill="#231F20"
    />
    <path
      d="M341.984 83.52C341.984 83.52 345.745 84.72 350.545 81.12C355.345 77.52 355.184 75.04 355.184 75.04C355.184 75.04 349.344 77.76 343.184 75.76L341.984 83.52Z"
      fill="#231F20"
    />
    <path
      d="M343.584 84.2399C342.944 84.2399 342.384 84.1599 341.744 83.9999C341.504 83.9199 341.344 83.6799 341.344 83.3599L342.464 75.5999C342.464 75.4399 342.544 75.2799 342.704 75.1999C342.864 75.1199 343.024 75.1199 343.184 75.1199C349.104 77.0399 354.784 74.4799 354.784 74.4799C354.944 74.3999 355.104 74.3999 355.264 74.4799C355.424 74.5599 355.504 74.7199 355.504 74.9599C355.504 75.2799 355.504 77.7599 350.624 81.5199C348.784 83.1999 346.224 84.1599 343.584 84.2399ZM342.624 83.1199C343.744 83.2799 346.624 83.4399 350.224 80.7199C352.944 78.6399 353.984 76.9599 354.384 75.9999C350.944 77.1999 347.184 77.3599 343.584 76.4799L342.624 83.1199Z"
      fill="#231F20"
    />
    <path
      d="M352.064 54.0801C352.064 54.0801 346.544 62.0801 347.824 63.5201C349.104 64.9601 352.704 64.0801 352.704 64.0801"
      fill="#D1D3D4"
    />
    <path
      d="M350.384 64.8801C349.264 64.9601 348.224 64.6401 347.344 63.8401C345.904 62.2401 349.824 56.2401 351.504 53.7601C351.664 53.5201 352.064 53.4401 352.304 53.6001C352.544 53.7601 352.624 54.1601 352.464 54.4001C349.744 58.4001 347.664 62.4801 348.144 63.1201C348.944 64.0801 351.584 63.6801 352.464 63.4401C352.784 63.3601 353.104 63.5201 353.184 63.8401C353.264 64.1601 353.104 64.4801 352.784 64.5601C351.984 64.7201 351.184 64.8801 350.384 64.8801Z"
      fill="#231F20"
    />
    <path
      d="M351.184 70.3201H350.784C346.144 70.1601 342.864 66.4801 342.704 66.3201C342.464 66.0801 342.464 65.7601 342.704 65.5201C342.944 65.2801 343.264 65.3601 343.504 65.5201C343.504 65.5201 346.704 68.9601 350.784 69.1201C353.264 69.1201 355.584 68.1601 357.264 66.4001C357.504 66.1601 357.824 66.1601 358.064 66.4001C358.304 66.6401 358.304 66.9601 358.064 67.2001C356.304 69.2001 353.824 70.3201 351.184 70.3201Z"
      fill="#231F20"
    />
    <path
      d="M359.423 53.0028C359.471 51.7666 359.08 50.7478 358.55 50.7274C358.02 50.7069 357.552 51.6925 357.504 52.9288C357.457 54.165 357.847 55.1838 358.377 55.2043C358.907 55.2247 359.375 54.2391 359.423 53.0028Z"
      fill="#231F20"
    />
    <path
      d="M345.56 51.4963C345.608 50.26 345.217 49.2412 344.687 49.2208C344.157 49.2003 343.689 50.186 343.642 51.4222C343.594 52.6585 343.985 53.6772 344.515 53.6977C345.044 53.7181 345.512 52.7325 345.56 51.4963Z"
      fill="#231F20"
    />
    <path
      d="M363.504 57.8401C364.544 56.4801 365.824 55.2801 367.264 54.4001C368.064 53.8401 368.944 53.6001 369.904 53.6001C370.624 53.6801 371.344 54.0001 371.984 54.4801C373.824 55.8401 374.944 57.8401 375.184 60.1601C375.264 61.2001 375.104 62.2401 374.784 63.2001C374.064 64.6401 372.864 65.8401 371.344 66.4801C369.824 67.1201 368.304 67.6001 366.704 67.9201"
      fill="#D1D3D4"
    />
    <path
      d="M366.624 68.4001C366.304 68.4001 366.064 68.1601 365.984 67.8401C365.984 67.5201 366.144 67.2801 366.464 67.2001C367.984 66.8801 369.504 66.4801 370.944 65.7601C372.304 65.1201 373.424 64.0802 374.064 62.7202C374.384 61.8402 374.544 60.8801 374.464 60.0001C374.224 57.9201 373.184 56.0001 371.504 54.8001C371.024 54.4001 370.384 54.1602 369.744 54.0802C368.944 54.0802 368.144 54.4001 367.504 54.8001C366.144 55.6801 364.864 56.8002 363.904 58.0802C363.744 58.3202 363.344 58.4001 363.104 58.2401C362.864 58.0801 362.784 57.6801 363.024 57.4401C364.064 56.0001 365.424 54.8001 366.944 53.8401C367.824 53.2801 368.864 52.8801 369.904 52.9601C370.784 53.0401 371.584 53.3602 372.304 53.9202C374.224 55.3602 375.504 57.6001 375.744 60.0001C375.824 61.1201 375.664 62.2402 375.264 63.2802C374.464 64.8802 373.184 66.1602 371.584 66.8802C370.064 67.6002 368.464 68.0801 366.864 68.4001H366.624Z"
      fill="#231F20"
    />
    <path d="M386.624 112.32C383.744 116.08 379.824 121.92 375.664 128.56H386.624V112.32Z" fill="#27AE60" />
    <path
      d="M375.665 129.12C375.345 129.12 375.104 128.88 375.104 128.56C375.104 128.48 375.104 128.4 375.184 128.32L386.145 103.28C386.305 102.96 386.625 102.88 386.945 102.96C387.265 103.12 387.345 103.44 387.265 103.68L376.304 128.72C376.144 128.96 375.905 129.12 375.665 129.12Z"
      fill="#231F20"
    />
    <path
      d="M310.064 20.5601C309.904 20.5601 309.824 20.5601 309.744 20.4801C309.504 20.3201 309.424 19.9201 309.664 19.6801C313.184 14.9601 318.784 16.2401 319.024 16.2401C319.344 16.3201 319.504 16.5601 319.504 16.8801C319.424 17.2001 319.184 17.3601 318.864 17.3601C318.864 17.3601 318.864 17.3601 318.784 17.3601C318.544 17.2801 313.584 16.2401 310.624 20.3201C310.464 20.4801 310.224 20.5601 310.064 20.5601Z"
      fill="#231F20"
    />
  </svg>
);

export const ContactUsIconWithLine = () => (
  <svg viewBox="0 0 819 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M297.425 101.2C297.425 101.2 297.345 95.3598 315.505 85.6798C333.665 75.9998 361.105 78.5599 370.705 84.3199C380.305 90.0799 385.025 91.1999 388.945 103.92C391.345 112 392.625 120.32 392.705 128.72H294.385L297.425 101.2Z"
      fill="#27AE60"
    />
    <path
      d="M331.904 111.52C334.064 113.28 343.744 115.04 345.984 114.48C351.904 112.96 358.144 110.88 361.824 105.68C363.904 102.8 364.864 99.1998 365.664 95.6798C366.544 91.5998 367.264 87.1998 366.144 83.1998C362.144 69.5998 341.184 68.1598 330.704 73.6798C315.584 81.6798 321.344 102.72 331.904 111.52Z"
      fill="white"
    />
    <path
      d="M344.705 115.12C341.025 115.12 333.505 113.52 331.585 111.92C324.625 106.08 320.145 95.5998 321.105 87.0398C321.585 81.1198 325.105 75.9198 330.385 73.1998C337.585 69.3598 348.545 69.0398 356.465 72.3998C361.745 74.6398 365.265 78.3198 366.625 83.0398C367.825 87.1998 367.105 91.5998 366.225 95.8398C365.505 99.1198 364.545 102.96 362.305 106C358.625 111.2 352.385 113.36 346.145 114.96C345.665 115.12 345.185 115.2 344.705 115.12ZM332.305 111.12C334.225 112.72 343.665 114.48 345.825 113.92C351.825 112.4 357.905 110.24 361.345 105.36C363.425 102.4 364.385 98.7998 365.025 95.5998C365.905 91.5198 366.625 87.1998 365.505 83.3598C364.225 78.9598 360.945 75.5198 355.985 73.3598C348.465 70.1598 337.745 70.5598 330.865 74.1598C325.905 76.6398 322.625 81.5998 322.145 87.1198C321.265 95.4398 325.585 105.52 332.305 111.12Z"
      fill="#231F20"
    />
    <path
      d="M337.184 23.2797C327.024 25.0397 317.904 31.1997 318.064 42.5597C318.224 49.5197 321.904 55.9997 327.184 60.5597C334.864 67.2797 346.144 69.9197 355.584 66.1597C363.584 62.9597 369.664 54.9597 370.144 46.3197C370.544 39.4397 367.504 28.2397 360.304 25.7597C357.584 24.7997 354.144 26.9597 354.144 26.9597C352.704 23.0397 341.424 22.5597 337.184 23.2797Z"
      fill="white"
    />
    <path
      d="M346.464 68.3997C339.264 68.3997 332.304 65.7597 326.784 61.0397C321.024 55.9997 317.664 49.2797 317.504 42.6397C317.344 32.3997 324.624 25.0397 337.024 22.7997C341.264 22.0797 352.064 22.4797 354.224 26.3197C355.344 25.7597 357.984 24.5597 360.304 25.3597C367.584 27.8397 370.944 39.1997 370.544 46.4797C370.064 55.1997 363.984 63.5197 355.664 66.7997C352.784 67.9197 349.584 68.4797 346.464 68.3997ZM341.264 23.5197C339.904 23.5197 338.544 23.5997 337.184 23.8397C325.344 25.9197 318.304 32.9597 318.464 42.6397C318.544 49.0397 321.824 55.4397 327.344 60.3197C335.104 67.1197 346.384 69.3597 355.264 65.8397C363.184 62.7197 369.024 54.7197 369.504 46.3997C369.904 39.5197 366.784 28.7197 359.984 26.3197C357.504 25.4397 354.304 27.5197 354.224 27.5197C354.064 27.5997 353.904 27.5997 353.744 27.5997C353.584 27.5197 353.504 27.4397 353.424 27.2797C352.704 24.4797 346.224 23.5197 341.264 23.5197Z"
      fill="#231F20"
    />
    <path
      d="M355.184 77.0398L355.264 91.8398C355.264 91.8398 353.744 100.16 344.704 97.5998C339.344 95.9998 334.864 92.1598 332.704 86.9598C332.704 86.9598 331.984 55.6798 331.744 46.2398C342.704 46.0798 354.784 39.0398 354.784 39.0398C354.784 39.0398 358.544 44.7198 360.224 46.4798C361.504 47.8398 362.704 49.3598 363.584 50.9598C365.104 53.8398 365.024 57.3598 364.944 60.5598C364.944 64.3198 364.544 68.0798 363.824 71.7598C362.464 78.7998 355.184 77.0398 355.184 77.0398Z"
      fill="white"
    />
    <path
      d="M347.904 98.6398C346.784 98.6398 345.664 98.4798 344.544 98.1598C338.944 96.5598 334.464 92.5598 332.224 87.1998C332.224 87.1198 332.224 87.1198 332.224 87.0398C332.224 86.7198 331.504 55.6798 331.264 46.3198C331.264 46.1598 331.344 46.0798 331.424 45.9198C331.504 45.8398 331.664 45.7598 331.744 45.7598C342.464 45.5998 354.384 38.6398 354.544 38.5598C354.784 38.3998 355.104 38.4798 355.264 38.7198C355.264 38.7998 359.024 44.3998 360.624 46.0798C361.984 47.4398 363.104 48.9598 364.064 50.6398C365.664 53.6798 365.584 57.4398 365.504 60.4798C365.504 64.2398 365.104 67.9998 364.384 71.7598C364.064 73.6798 363.024 75.3598 361.344 76.4798C359.584 77.4398 357.584 77.8398 355.584 77.5198L355.664 91.7598C355.184 94.0798 353.904 96.0798 351.984 97.3598C350.864 98.2398 349.424 98.6398 347.904 98.6398ZM333.264 86.8798C335.424 91.8398 339.664 95.5998 344.864 97.1198C347.584 97.8398 349.744 97.6798 351.504 96.6398C353.104 95.4398 354.304 93.7598 354.704 91.7598L354.624 77.0398C354.624 76.8798 354.704 76.7198 354.784 76.6398C354.944 76.5598 355.104 76.4798 355.184 76.5598C355.184 76.5598 358.384 77.2798 360.784 75.7598C362.144 74.7998 363.104 73.3598 363.344 71.6798C364.064 67.9998 364.384 64.3198 364.464 60.5598C364.544 57.5198 364.624 53.9998 363.104 51.1998C362.224 49.5998 361.104 48.1598 359.824 46.7998C358.384 45.2798 355.504 41.0398 354.624 39.6798C352.384 40.8798 341.984 46.3998 332.224 46.7198C332.544 56.4798 333.184 85.1198 333.264 86.8798Z"
      fill="#231F20"
    />
    <path
      d="M355.105 84.3195C355.105 84.3195 349.105 84.5595 345.185 80.5595C341.265 76.5595 341.905 72.4795 341.905 72.4795C341.905 72.4795 348.385 76.8795 355.185 77.0395L355.105 84.3195Z"
      fill="#231F20"
    />
    <path
      d="M354.784 84.8799C353.504 84.8799 348.304 84.6399 344.784 80.9599C340.784 76.7999 341.344 72.6399 341.344 72.4799C341.344 72.3199 341.504 72.1599 341.664 72.0799C341.824 71.9999 341.984 71.9999 342.144 72.1599C342.224 72.1599 348.624 76.4799 355.184 76.5599C355.424 76.5599 355.664 76.7999 355.664 77.0399L355.584 84.3199C355.584 84.5599 355.344 84.7999 355.104 84.7999L354.784 84.8799ZM342.384 73.4399C342.544 75.9999 343.744 78.4799 345.584 80.2399C348.624 83.4399 353.184 83.8399 354.624 83.8399L354.704 77.5199C349.264 77.2799 344.224 74.5599 342.384 73.4399Z"
      fill="#231F20"
    />
    <path
      d="M349.344 53.3596C349.344 53.3596 354.144 60.4796 353.024 61.7596C351.904 63.0396 348.704 62.1596 348.704 62.1596"
      fill="white"
    />
    <path
      d="M350.784 62.9596C350.064 62.9596 349.264 62.8796 348.544 62.6396C348.304 62.5596 348.144 62.3196 348.224 61.9996C348.304 61.7596 348.544 61.5996 348.784 61.6796C349.504 61.8396 351.824 62.2396 352.624 61.4396C353.104 60.9596 351.344 57.2796 348.944 53.6796C348.784 53.5196 348.704 53.1996 348.944 52.9596C349.104 52.7996 349.424 52.7196 349.664 52.9596C349.744 53.0396 349.744 53.0396 349.744 53.1196C351.264 55.3596 354.624 60.7196 353.344 62.0796C352.624 62.7196 351.744 63.0396 350.784 62.9596Z"
      fill="#231F20"
    />
    <path
      d="M355.638 52.6346C356.079 52.6172 356.411 51.9232 356.378 51.0844C356.345 50.2456 355.96 49.5797 355.519 49.597C355.077 49.6144 354.746 50.3084 354.779 51.1472C354.812 51.986 355.196 52.6519 355.638 52.6346Z"
      fill="#231F20"
    />
    <path
      d="M342.643 50.7671C342.668 49.9279 342.331 49.2371 341.889 49.224C341.447 49.2109 341.069 49.8805 341.044 50.7196C341.019 51.5588 341.357 52.2496 341.799 52.2627C342.24 52.2759 342.619 51.6062 342.643 50.7671Z"
      fill="#231F20"
    />
    <path
      d="M334.864 55.9996C333.264 53.7596 330.864 49.7596 327.584 50.9596C326.704 51.2796 325.984 51.9196 325.344 52.6396C322.704 55.7596 323.104 59.9196 324.864 63.3596C326.384 66.3196 331.104 70.7996 334.304 67.0396"
      fill="white"
    />
    <path
      d="M331.425 68.9597C331.265 68.9597 331.105 68.9597 330.945 68.9597C327.985 68.6397 325.345 65.4397 324.385 63.5997C322.305 59.4397 322.465 55.1997 324.945 52.3197C325.585 51.5197 326.385 50.8797 327.345 50.4797C330.705 49.1997 333.265 52.7997 334.865 55.1197L335.265 55.6797C335.425 55.9197 335.345 56.2397 335.185 56.3997C334.945 56.5597 334.625 56.4797 334.465 56.3197L334.065 55.7597C332.545 53.5197 330.385 50.4797 327.745 51.4397C326.945 51.7597 326.225 52.2397 325.745 52.9597C323.585 55.5197 323.425 59.3597 325.345 63.1197C326.305 64.9597 328.625 67.6797 331.105 67.9197C332.225 68.0797 333.265 67.5997 333.985 66.7197C334.145 66.4797 334.465 66.4797 334.705 66.6397C334.945 66.7997 334.945 67.1197 334.785 67.3597C333.825 68.3997 332.625 68.9597 331.425 68.9597Z"
      fill="#231F20"
    />
    <path
      d="M349.904 69.9198C349.664 69.9198 349.344 69.9198 349.104 69.8398C344.384 69.2798 341.104 64.4798 340.944 64.2398C340.864 63.9998 340.944 63.6798 341.184 63.5998C341.344 63.5198 341.584 63.5998 341.744 63.6798C341.744 63.7598 344.944 68.3998 349.184 68.8798C351.264 69.1198 353.344 68.3198 355.264 66.4798C355.424 66.3198 355.744 66.2398 355.984 66.4798C356.144 66.6398 356.224 66.9598 355.984 67.1998C355.984 67.1998 355.984 67.1998 355.904 67.1998C354.384 68.8798 352.224 69.8398 349.904 69.9198Z"
      fill="#231F20"
    />
    <path
      d="M313.505 128.28C313.185 128.28 312.945 128.04 312.945 127.72V104.44C312.945 104.12 313.185 103.88 313.505 103.88C313.825 103.88 314.065 104.12 314.065 104.44V127.72C314.065 128.04 313.825 128.28 313.505 128.28Z"
      fill="#231F20"
    />
    <path
      d="M372.496 128C372.286 128 372.076 127.849 372.006 127.546L368.014 104.62C367.944 104.317 368.154 104.09 368.434 104.015C368.714 103.939 368.924 104.166 368.994 104.469L372.986 127.395C373.056 127.697 372.846 127.924 372.566 128H372.496Z"
      fill="#231F20"
    />
    <path
      d="M345.024 95.8398C345.024 95.8398 332.464 109.12 333.104 122.16L354.224 124.56C354.224 124.56 350.384 101.52 352.784 95.7598L345.024 95.8398Z"
      fill="white"
    />
    <path
      d="M354.224 125.12L333.024 122.72C332.784 122.72 332.544 122.48 332.544 122.16C331.904 109.04 344.064 95.9996 344.624 95.4396C344.704 95.3596 344.864 95.2796 345.024 95.2796L352.784 95.1196C352.944 95.1196 353.184 95.1996 353.264 95.3596C353.344 95.5196 353.424 95.6796 353.344 95.9196C351.024 101.52 354.784 124.24 354.864 124.48C354.944 124.8 354.704 125.04 354.384 125.12C354.304 125.12 354.224 125.12 354.224 125.12ZM333.664 121.6L353.584 123.92C352.944 120.08 350.304 102.48 351.984 96.3196L345.264 96.3996C343.824 97.9996 333.344 109.92 333.664 121.6Z"
      fill="#231F20"
    />
    <path
      d="M343.665 110.72H343.584C343.264 110.72 343.104 110.4 343.104 110.08C343.744 105.68 345.424 101.52 347.984 97.8397C348.144 97.5997 348.544 97.5197 348.784 97.7597C349.024 97.9197 349.105 98.3197 348.865 98.5597C346.385 102.08 344.864 106.08 344.224 110.24C344.144 110.56 343.905 110.72 343.665 110.72Z"
      fill="#D1D3D4"
    />
    <path
      d="M356.224 100.56C356.144 100.56 356.064 100.56 355.984 100.56C355.664 100.4 355.584 100.08 355.664 99.8395C356.864 97.2795 357.744 94.6395 358.464 91.9995C359.184 89.3595 359.104 86.5595 358.304 83.9195C358.224 83.5995 358.384 83.2795 358.624 83.1995C358.944 83.1195 359.264 83.2795 359.344 83.5195C360.224 86.3195 360.224 89.3595 359.504 92.1595C358.784 94.9595 357.824 97.5995 356.624 100.24C356.624 100.48 356.384 100.56 356.224 100.56Z"
      fill="#D1D3D4"
    />
    <path
      d="M343.824 55.5997H337.504C335.264 55.5997 333.504 53.8397 333.504 51.5997V47.2797C333.504 46.9597 333.744 46.7197 334.064 46.7197H347.264C347.584 46.7197 347.824 46.9597 347.824 47.2797V51.5997C347.824 53.7597 346.064 55.5997 343.824 55.5997ZM334.624 47.8397V51.5997C334.624 53.1997 335.904 54.4797 337.504 54.4797H343.824C345.424 54.4797 346.704 53.1997 346.704 51.5997V47.8397H334.624Z"
      fill="#231F20"
    />
    <path
      d="M359.504 55.5997H353.184C350.944 55.5997 349.184 53.8397 349.184 51.5997V47.2797C349.184 46.9597 349.424 46.7197 349.744 46.7197H362.944C363.264 46.7197 363.504 46.9597 363.504 47.2797V51.5997C363.504 53.7597 361.744 55.5997 359.504 55.5997ZM350.304 47.8397V51.5997C350.304 53.1997 351.584 54.4797 353.184 54.4797H359.504C361.104 54.4797 362.384 53.1997 362.384 51.5997V47.8397H350.304Z"
      fill="#231F20"
    />
    <path
      d="M353.184 47.8397H346.304C345.984 47.8397 345.744 47.5997 345.744 47.2797C345.744 46.9597 345.984 46.7197 346.304 46.7197H353.184C353.504 46.7197 353.744 46.9597 353.744 47.2797C353.744 47.5997 353.504 47.8397 353.184 47.8397Z"
      fill="#231F20"
    />
    <line x1="-6.10352e-05" y1="128.5" x2="819" y2="128.5" stroke="black" />
    <path
      d="M247.504 79.1997C247.504 79.1997 227.904 79.8397 220.224 93.9997C212.544 108.16 212.224 128.8 212.224 128.8H301.584C301.584 128.8 291.584 94.7997 278.144 86.7197C264.704 78.6397 247.504 79.1997 247.504 79.1997Z"
      fill="#27AE60"
    />
    <path
      d="M225.744 123.44C225.664 123.44 225.584 123.44 225.504 123.36C225.264 123.2 225.104 122.88 225.264 122.64C225.664 121.84 230.144 105.68 233.024 95.04C233.104 94.72 233.424 94.56 233.744 94.64C234.064 94.72 234.224 95.04 234.144 95.28C233.344 98 226.704 122.16 226.304 123.04C226.224 123.28 225.984 123.44 225.744 123.44Z"
      fill="#231F20"
    />
    <path
      d="M258.064 28.9599C258.064 28.9599 263.024 16.3999 250.304 13.1999C237.584 9.99989 225.904 18.3999 227.344 29.5999C228.784 40.7999 237.344 41.0399 237.344 41.0399L258.064 28.9599Z"
      fill="white"
    />
    <path
      d="M237.344 41.5999C237.264 41.5999 228.304 41.2799 226.784 29.6799C226.064 24.7999 227.904 19.9199 231.664 16.7199C236.464 12.4799 243.424 10.9599 250.384 12.6399C254.224 13.5999 256.944 15.5199 258.384 18.3199C260.864 23.1999 258.704 28.8799 258.624 29.1999C258.544 29.2799 258.464 29.4399 258.384 29.5199L237.664 41.5999C237.584 41.5199 237.424 41.5999 237.344 41.5999ZM244.704 13.1199C240.144 13.0399 235.824 14.6399 232.384 17.5999C228.944 20.5599 227.184 25.1199 227.824 29.5999C229.184 39.5199 236.064 40.3999 237.184 40.4799L257.584 28.5599C257.904 27.5999 259.424 22.7999 257.344 18.7999C256.064 16.3199 253.584 14.6399 250.064 13.7599C248.384 13.2799 246.544 13.1199 244.704 13.1199Z"
      fill="#231F20"
    />
    <path
      d="M239.425 30.2399C241.345 29.0399 243.425 28.2399 245.585 27.8399C251.585 26.6399 258.465 27.6799 262.625 32.1599C260.625 30.6399 257.905 31.8399 255.505 32.6399C253.425 33.3599 251.265 33.6799 249.185 34.3199C246.385 35.1999 243.825 36.5599 241.505 38.3199C240.145 39.3599 236.945 43.9199 235.345 43.8399C232.385 43.5999 233.665 38.4799 234.225 36.9599C235.265 34.1599 237.025 31.8399 239.425 30.2399Z"
      fill="#D1D3D4"
    />
    <path
      d="M235.584 44.3998H235.504C234.784 44.3998 234.064 43.9998 233.664 43.3598C232.304 41.3598 233.744 37.1999 233.904 36.7199C234.864 33.8399 236.704 31.4398 239.184 29.7598C241.104 28.5598 243.344 27.6799 245.584 27.2799C251.024 26.1599 258.464 26.7998 263.184 31.7598C263.424 31.9998 263.424 32.3198 263.184 32.5598C262.944 32.7998 262.624 32.7998 262.384 32.5598C260.784 31.3598 258.544 32.1599 256.384 32.8799L255.744 33.1199C254.624 33.5199 253.424 33.8399 252.304 34.0799C251.344 34.3199 250.384 34.5598 249.504 34.7998C246.784 35.6798 244.224 36.9599 241.984 38.7199C241.344 39.2799 240.704 39.9198 240.144 40.6398C238.224 42.8798 236.784 44.3998 235.584 44.3998ZM250.224 27.9998C248.704 27.9998 247.184 28.1599 245.744 28.4799C243.664 28.8799 241.584 29.5998 239.744 30.7998C237.504 32.3198 235.744 34.5598 234.944 37.1998C234.384 38.7998 233.824 41.6798 234.624 42.7998C234.864 43.1198 235.184 43.3598 235.584 43.3598C236.304 43.3598 238.224 41.2798 239.344 40.0798C239.984 39.2799 240.624 38.6398 241.344 37.9998C243.664 36.1598 246.384 34.7999 249.184 33.9199C250.144 33.5999 251.104 33.3599 252.064 33.1199C253.184 32.8799 254.304 32.5598 255.424 32.1598L256.064 31.9998C257.424 31.4398 258.864 31.1198 260.384 30.9598C257.264 28.8798 253.744 27.9198 250.224 27.9998Z"
      fill="#231F20"
    />
    <path
      d="M270.864 33.04C264.064 29.28 254.944 28.64 247.504 30.88C240.064 33.12 235.344 39.2 233.584 46.72C232.384 51.92 231.424 59.84 235.664 63.68C240.304 67.92 245.824 70.16 252.064 71.2C258.464 72.16 266.384 72.08 271.664 67.92C276.944 63.76 280.464 56.4 280.624 49.92C280.944 41.84 276.784 36.32 270.864 33.04Z"
      fill="white"
    />
    <path
      d="M258.385 72.2399C256.225 72.2399 254.065 72.0799 251.985 71.7599C245.185 70.7199 239.745 68.2399 235.265 64.1599C230.945 60.2399 231.665 52.4799 233.025 46.6399C234.945 38.4799 240.145 32.4799 247.345 30.3999C255.105 28.0799 264.465 28.9599 271.105 32.6399C277.825 36.3199 281.425 42.4799 281.185 49.9199C280.945 56.7199 277.185 64.3199 271.985 68.3999C268.145 71.4399 262.945 72.2399 258.385 72.2399ZM256.465 30.1599C253.505 30.1599 250.545 30.5599 247.665 31.3599C240.785 33.4399 235.985 38.8799 234.145 46.7999C233.105 51.3599 231.905 59.5199 236.065 63.1999C240.385 67.1199 245.665 69.5199 252.225 70.5599C256.945 71.2799 265.665 71.8399 271.425 67.3599C276.385 63.5199 279.985 56.2399 280.145 49.7599C280.465 40.7999 275.105 35.9199 270.625 33.4399C266.225 31.2799 261.345 30.0799 256.465 30.1599Z"
      fill="#231F20"
    />
    <path
      d="M264.705 78.48L265.665 92C265.665 92 264.385 98.32 255.185 96.8C245.985 95.28 244.145 89.2 244.145 89.2L243.265 63.92C243.265 63.92 256.785 55.28 253.665 40.96C253.665 40.96 260.065 48.8 273.265 46C273.265 46 274.465 62.08 273.985 69.12C273.505 76.32 264.705 78.48 264.705 78.48Z"
      fill="white"
    />
    <path
      d="M257.745 97.5999C256.865 97.5999 255.985 97.5199 255.105 97.3599C245.665 95.8399 243.745 89.6799 243.665 89.4399C243.665 89.3599 243.665 89.3599 243.665 89.2799L242.785 63.9999C242.785 63.8399 242.865 63.5999 243.025 63.5199C243.185 63.4399 256.225 54.8799 253.185 41.1199C253.105 40.7999 253.345 40.4799 253.585 40.4799C253.825 40.3999 253.985 40.4799 254.145 40.6399C254.225 40.7199 260.465 48.1599 273.265 45.5199C273.425 45.5199 273.585 45.5199 273.745 45.5999C273.905 45.6799 273.985 45.8399 273.985 45.9999C273.985 46.1599 275.185 62.2399 274.705 69.2799C274.225 75.7599 267.345 78.3199 265.425 78.8799L266.385 91.9199C266.385 91.9999 266.385 91.9999 266.385 92.0799C266.225 92.1599 265.025 97.5999 257.745 97.5999ZM244.705 89.1999C244.945 89.8399 246.945 94.9599 255.265 96.3199C263.345 97.5999 264.945 92.6399 265.105 91.9999L264.145 78.5599C264.145 78.3199 264.305 78.0799 264.545 77.9999C264.625 77.9999 272.945 75.8399 273.425 69.2799C273.905 63.1199 273.025 49.8399 272.785 46.7999C262.945 48.6399 256.945 44.7999 254.545 42.7199C256.145 55.1199 245.665 63.1199 243.825 64.3999L244.705 89.1999Z"
      fill="#231F20"
    />
    <path
      d="M247.504 62.96C247.104 61.76 246.304 60.72 245.264 60C244.224 59.36 243.104 58.88 241.984 58.64C241.344 58.4 240.704 58.4 240.064 58.48C239.584 58.56 239.184 58.8 238.864 59.04C237.904 59.6 237.184 60.48 236.864 61.52C236.704 62.4 236.704 63.28 236.944 64.08C237.104 65.04 237.424 65.92 237.824 66.8C239.664 70.32 243.984 71.68 247.504 69.84C247.504 69.84 247.584 69.84 247.584 69.76"
      fill="white"
    />
    <path
      d="M244.144 71.12C243.424 71.12 242.624 71.04 241.904 70.8C239.904 70.16 238.304 68.8 237.344 66.96C236.864 66.08 236.544 65.12 236.384 64.16C236.144 63.2 236.064 62.24 236.304 61.28C236.704 60.08 237.504 59.12 238.544 58.48C238.944 58.16 239.504 57.92 239.984 57.84C240.704 57.76 241.424 57.84 242.144 58C243.344 58.24 244.544 58.72 245.664 59.44C246.784 60.24 247.664 61.36 248.144 62.72C248.224 63.04 248.064 63.36 247.824 63.44C247.504 63.52 247.184 63.36 247.104 63.12C246.704 62 246.064 61.04 245.104 60.4C244.144 59.84 243.104 59.36 241.984 59.12C241.424 58.96 240.864 58.88 240.304 58.96C239.904 59.04 239.584 59.2 239.264 59.44C238.464 59.92 237.824 60.72 237.504 61.6C237.344 62.4 237.424 63.12 237.584 63.92C237.744 64.8 238.064 65.68 238.464 66.48C240.144 69.68 244.144 70.96 247.424 69.28H247.504C247.744 69.12 248.144 69.2 248.224 69.52C248.384 69.76 248.304 70.16 248.064 70.32C246.704 70.88 245.424 71.12 244.144 71.12Z"
      fill="#231F20"
    />
    <path
      d="M262.464 56.72C262.464 56.72 267.984 63.2 267.024 64.56C266.064 65.92 262.784 65.44 262.784 65.44"
      fill="white"
    />
    <path
      d="M263.985 66.0799C263.585 66.0799 263.105 66.0799 262.705 65.9999C262.385 65.9999 262.145 65.7599 262.145 65.4399C262.145 65.1199 262.385 64.8799 262.705 64.8799H262.785C263.505 64.9599 265.825 65.0399 266.465 64.1599C266.785 63.5199 264.625 60.1599 261.905 57.0399C261.665 56.7999 261.745 56.4799 261.985 56.2399C262.225 55.9999 262.545 56.0799 262.785 56.3199C264.945 58.8799 268.465 63.3599 267.345 64.8799C266.705 65.9199 265.185 66.0799 263.985 66.0799Z"
      fill="#231F20"
    />
    <path
      d="M266.624 69.2L255.984 65.52C255.984 65.52 253.984 69.44 257.824 73.2C261.984 77.28 266.624 69.2 266.624 69.2Z"
      fill="white"
    />
    <path
      d="M260.384 74.9598C259.264 74.9598 258.224 74.4798 257.504 73.6798C253.424 69.6798 255.584 65.3598 255.584 65.3598C255.744 65.1198 255.984 64.9599 256.304 65.0399L266.944 68.7199C267.104 68.7999 267.264 68.8799 267.264 69.0399C267.344 69.1999 267.344 69.3599 267.264 69.5199C267.184 69.6799 264.384 74.4798 261.024 74.9598C260.704 74.8798 260.544 74.9598 260.384 74.9598ZM256.384 66.2399C256.064 67.2799 255.504 70.0799 258.304 72.7999C258.944 73.5199 259.824 73.8398 260.784 73.7598C262.944 73.4398 265.024 70.7199 265.824 69.4399L256.384 66.2399Z"
      fill="#231F20"
    />
    <path
      d="M259.345 55.3599C259.265 55.3599 259.105 55.3599 259.025 55.2799C256.225 53.5999 254.545 54.2399 254.545 54.2399C254.225 54.3199 253.905 54.1599 253.825 53.9199C253.745 53.6799 253.825 53.3599 254.145 53.1999C254.225 53.1999 256.385 52.3199 259.665 54.3199C259.905 54.4799 259.985 54.7999 259.825 55.1199C259.745 55.2799 259.585 55.3599 259.345 55.3599Z"
      fill="#231F20"
    />
    <path
      d="M266.225 55.4399C266.065 55.4399 265.905 55.3599 265.825 55.2799C265.585 55.0399 265.585 54.7199 265.825 54.4799C265.905 54.3999 266.065 54.3199 266.225 54.3199L266.705 54.0799C267.745 53.4399 269.905 52.2399 272.065 53.1199C272.385 53.2799 272.465 53.5999 272.385 53.8399C272.305 54.1599 271.985 54.2398 271.665 54.1598C270.065 53.5198 268.225 54.4799 267.265 55.0399C266.945 55.1999 266.625 55.3599 266.225 55.4399Z"
      fill="#231F20"
    />
    <path
      d="M264.704 78.48C264.704 78.48 257.664 78.8 254.304 77.04C254.304 77.04 257.424 84.96 265.104 86.56L264.704 78.48Z"
      fill="#231F20"
    />
    <path
      d="M265.184 87.1999H265.024C257.104 85.5199 253.904 77.6799 253.824 77.2799C253.744 77.0399 253.824 76.7999 253.984 76.6399C254.144 76.4799 254.384 76.4799 254.624 76.5599C257.824 78.2399 264.624 77.9199 264.704 77.9199C265.024 77.9199 265.264 78.1599 265.264 78.4799L265.664 86.6399C265.664 86.7999 265.584 86.9599 265.504 87.1199C265.424 87.1999 265.264 87.1999 265.184 87.1999ZM255.504 78.1599C256.704 80.3199 259.584 84.5599 264.624 85.9199L264.224 79.1199C262.704 79.1199 258.544 79.1199 255.504 78.1599Z"
      fill="#231F20"
    />
    <path
      d="M229.984 128C232.064 120.32 225.024 106.88 223.024 100.16C221.184 93.9199 212.624 77.5999 214.224 71.1999C214.224 71.1999 218.144 55.6799 214.384 57.9999C212.304 59.2799 210.784 65.6799 210.784 65.6799C210.784 65.6799 208.464 51.5999 204.784 50.1599C201.104 48.7199 193.824 57.9999 194.944 61.9199C198.544 74.8799 199.904 88.5599 201.584 101.84C203.024 113.76 202.784 132.8 219.104 133.68C225.824 134 228.944 131.68 229.984 128Z"
      fill="white"
    />
    <path
      d="M220.384 134.24C219.984 134.24 219.504 134.24 219.024 134.24C204.144 133.44 202.544 117.76 201.424 106.32C201.264 104.8 201.104 103.28 200.944 101.92L200.384 97.36C199.024 85.6 197.584 73.52 194.384 62.08C193.584 59.36 196.464 54.48 199.504 51.68C201.584 49.76 203.504 49.12 204.944 49.6C207.984 50.8 209.984 58.96 210.864 63.2C211.504 61.12 212.624 58.4 214.064 57.52C214.544 57.12 215.184 57.12 215.744 57.44C217.744 58.88 215.504 68.48 214.704 71.36C213.584 75.68 217.584 85.36 220.544 92.4C221.824 95.52 222.944 98.16 223.504 100C224.064 101.84 224.944 104 225.824 106.32C228.624 113.44 232.064 122.32 230.464 128.08C229.424 132.24 225.984 134.24 220.384 134.24ZM203.984 50.56C203.024 50.56 201.664 51.2 200.304 52.48C197.184 55.28 194.944 59.68 195.504 61.76C198.704 73.28 200.144 85.44 201.584 97.2L202.144 101.76C202.304 103.12 202.464 104.64 202.624 106.16C203.744 117.76 205.184 132.32 219.184 133.04C225.024 133.36 228.384 131.6 229.424 127.84C230.944 122.32 227.504 113.68 224.784 106.72C223.824 104.32 222.944 102.08 222.384 100.32C221.824 98.56 220.784 95.92 219.504 92.88C216.384 85.28 212.384 75.84 213.584 71.12C215.024 65.52 215.904 59.04 215.024 58.4C215.024 58.4 214.864 58.4 214.624 58.56C213.024 59.52 211.664 64.16 211.344 65.92C211.264 66.16 211.024 66.4 210.784 66.4C210.544 66.4 210.304 66.16 210.224 65.92C209.584 62.08 207.344 51.92 204.624 50.8C204.384 50.64 204.224 50.56 203.984 50.56Z"
      fill="#231F20"
    />
    <path
      d="M202.705 72.3998H202.545C202.225 72.3198 202.065 71.9998 202.145 71.7598C203.185 67.4398 210.225 65.2798 210.545 65.1998C210.865 65.1198 211.185 65.2798 211.265 65.5998C211.345 65.9198 211.185 66.2398 210.865 66.3198C210.785 66.3198 204.145 68.3998 203.185 72.0798C203.185 72.2398 203.025 72.3998 202.705 72.3998Z"
      fill="#231F20"
    />
    <path
      d="M280.704 128.28C280.464 128.28 280.224 128.04 280.144 127.8L275.344 96.1199C275.264 95.7999 275.424 95.4799 275.744 95.4799C276.064 95.3999 276.384 95.5599 276.384 95.8799V95.9599L281.184 127.64C281.264 127.96 281.024 128.2 280.704 128.28Z"
      fill="#231F20"
    />
    <path
      d="M241.425 109.52C241.345 109.52 241.265 109.52 241.185 109.52C240.865 109.44 240.785 109.12 240.865 108.8L246.865 93.5998C246.945 93.2798 247.265 93.1998 247.585 93.2798C247.905 93.3598 247.985 93.6798 247.905 93.9998L241.905 109.2C241.825 109.36 241.585 109.52 241.425 109.52Z"
      fill="#231F20"
    />
    <path
      d="M248.864 99.2798C248.784 99.2798 248.784 99.2798 248.704 99.2798L242.224 97.0398C241.904 96.9598 241.744 96.6398 241.904 96.3198C241.984 95.9998 242.304 95.8398 242.624 95.9998L249.104 98.1598C249.424 98.2398 249.584 98.5598 249.424 98.8798C249.264 99.1198 249.104 99.1998 248.864 99.2798Z"
      fill="#231F20"
    />
    <path
      d="M247.425 104.56C247.345 104.56 247.265 104.56 247.185 104.56L240.545 101.92C240.225 101.76 240.145 101.44 240.225 101.2C240.305 100.88 240.625 100.8 240.945 100.88L247.585 103.52C247.905 103.6 247.985 104 247.905 104.24C247.825 104.4 247.665 104.56 247.425 104.56Z"
      fill="#231F20"
    />
    <path
      d="M388.064 100.72C390.464 93.1195 394.784 90.3995 412.944 80.7195C431.104 71.0395 457.744 78.3195 467.344 84.0795C476.944 89.8395 483.984 98.3195 489.344 104.96C494.704 111.6 494.704 128.559 494.704 128.559H385.984C385.984 128.559 385.744 108.32 388.064 100.72Z"
      fill="#27AE60"
    />
    <path
      d="M466.784 128.12C466.464 128.12 466.224 127.88 466.224 127.64L464.064 102.12C464.064 101.8 464.304 101.559 464.544 101.479C464.864 101.479 465.104 101.719 465.184 101.959L467.344 127.479C467.344 127.799 467.104 128.04 466.784 128.12Z"
      fill="#231F20"
    />
    <path
      d="M436.625 20.9596C449.585 22.0796 455.665 28.3196 459.105 55.3596C461.825 76.6396 460.065 74.9597 473.905 87.9997C473.905 87.9997 461.265 105.28 435.665 105.28C410.065 105.28 392.385 91.9997 392.385 91.9997C392.385 91.9997 411.745 80.1596 411.345 68.5596C410.145 28.1596 423.665 19.8396 436.625 20.9596Z"
      fill="white"
    />
    <path
      d="M435.744 105.84C420.224 105.76 405.024 101.04 392.144 92.3996C391.984 92.3196 391.904 92.0796 391.904 91.9196C391.904 91.7596 391.984 91.5996 392.144 91.4396C392.304 91.3596 411.184 79.6796 410.864 68.5596C410.224 47.0396 413.664 32.4796 421.184 25.3596C425.264 21.5196 430.464 19.8396 436.624 20.3996C450.944 21.5996 456.304 29.7596 459.584 55.2796C459.904 57.6796 460.144 59.7596 460.384 61.5996C462.144 76.0796 462.144 76.0796 474.224 87.4396L474.304 87.5196C474.544 87.6796 474.544 87.9996 474.384 88.2396C474.304 88.4796 461.264 105.84 435.744 105.84ZM393.504 91.9996C406.064 100.16 420.784 104.64 435.744 104.72C458.544 104.72 471.104 90.5596 473.184 87.9996C461.104 76.6396 461.104 76.5596 459.344 61.7596C459.104 59.9196 458.864 57.8396 458.544 55.4396C455.024 27.9196 448.624 22.5596 436.544 21.5196C430.624 21.0396 425.744 22.5596 421.904 26.2396C414.624 33.1196 411.264 47.3596 411.904 68.5596C412.304 79.1996 397.024 89.6796 393.504 91.9996Z"
      fill="#231F20"
    />
    <path
      d="M434.625 72.5596C442.622 72.5596 449.105 63.283 449.105 51.8396C449.105 40.3963 442.622 31.1196 434.625 31.1196C426.627 31.1196 420.145 40.3963 420.145 51.8396C420.145 63.283 426.627 72.5596 434.625 72.5596Z"
      fill="white"
    />
    <path
      d="M434.625 73.1196C421.345 73.1196 419.505 62.8796 419.505 51.8396C419.505 40.3996 419.505 30.5596 434.625 30.5596C449.745 30.5596 449.665 40.3996 449.665 51.8396C449.745 62.8796 447.905 73.1196 434.625 73.1196ZM434.625 31.6796C420.625 31.6796 420.625 40.1596 420.625 51.8396C420.625 63.9196 423.025 71.9996 434.625 71.9996C446.225 71.9996 448.545 63.9196 448.545 51.8396C448.625 40.1596 448.625 31.6796 434.625 31.6796Z"
      fill="#231F20"
    />
    <path
      d="M431.985 48.6396C431.985 48.6396 426.465 55.1197 427.425 56.4797C428.385 57.8397 431.585 57.3596 431.585 57.3596"
      fill="white"
    />
    <path
      d="M430.465 57.9997C429.345 57.9997 427.745 57.8397 427.025 56.7997C425.985 55.2797 429.425 50.7997 431.585 48.3197C431.745 48.0797 432.145 48.0797 432.385 48.2397C432.625 48.3997 432.625 48.7997 432.465 49.0397C429.745 52.2397 427.665 55.5997 427.985 56.1597C428.625 57.0397 430.865 56.9597 431.665 56.7997C431.985 56.7997 432.225 56.9597 432.305 57.2797C432.385 57.5997 432.145 57.8397 431.825 57.9197C431.265 57.9997 430.865 57.9997 430.465 57.9997Z"
      fill="#231F20"
    />
    <path
      d="M426.078 48.4024C426.74 48.3987 427.273 47.6435 427.268 46.7157C427.263 45.7878 426.722 45.0387 426.059 45.0424C425.396 45.0461 424.863 45.8012 424.868 46.7291C424.873 47.6569 425.415 48.4061 426.078 48.4024Z"
      fill="#231F20"
    />
    <path
      d="M438.478 48.4024C439.14 48.3987 439.673 47.6435 439.668 46.7157C439.663 45.7878 439.122 45.0387 438.459 45.0424C437.796 45.0461 437.263 45.8012 437.268 46.7291C437.273 47.6569 437.815 48.4061 438.478 48.4024Z"
      fill="#231F20"
    />
    <path
      d="M427.824 61.1197L438.464 57.4397C438.464 57.4397 440.464 61.3597 436.624 65.1197C432.464 69.1997 427.824 61.1197 427.824 61.1197Z"
      fill="white"
    />
    <path
      d="M434.065 66.8797C433.905 66.8797 433.745 66.8797 433.585 66.8797C430.225 66.3997 427.425 61.6797 427.345 61.4397C427.185 61.1997 427.265 60.7997 427.585 60.6397H427.665L438.305 56.9597C438.545 56.8797 438.865 56.9597 439.025 57.1997C439.025 57.2797 441.105 61.5197 437.105 65.5197C436.225 66.3997 435.185 66.7997 434.065 66.8797ZM428.625 61.3597C429.425 62.5597 431.505 65.3597 433.745 65.6797C434.705 65.7597 435.585 65.4397 436.225 64.7197C439.025 61.9997 438.465 59.1997 438.145 58.1597L428.625 61.3597Z"
      fill="#231F20"
    />
    <path
      d="M411.904 128.56C411.584 128.56 410.784 128.88 410.784 128.56V106.32C410.784 106 411.024 105.76 411.344 105.76C411.664 105.76 411.904 106 411.904 106.32V128.56C412.224 128.56 411.904 128.88 411.904 128.56Z"
      fill="#231F20"
    />
    <path
      d="M436.064 85.9196C431.024 85.8396 425.984 85.1196 421.104 83.7596C420.784 83.6796 420.624 83.3596 420.704 83.0396C420.784 82.7196 421.104 82.5596 421.344 82.6396C434.064 86.0796 442.704 84.6396 447.744 82.7996C453.184 80.7996 455.584 77.9996 455.664 77.9996C455.904 77.7596 456.224 77.7596 456.464 77.9196C456.704 78.0796 456.704 78.4796 456.544 78.7196C456.144 79.1196 450.224 85.9196 436.064 85.9196Z"
      fill="#D1D3D4"
    />
    <path
      d="M386.162 129C385.942 129 385.722 128.843 385.649 128.529C383.084 112.661 388.947 97.4997 389.021 97.3425C389.167 97.0283 389.46 96.9498 389.68 97.0283C389.974 97.1854 390.047 97.4997 389.974 97.7353C389.9 97.8924 384.183 112.818 386.675 128.372C386.748 128.686 386.529 128.921 386.309 129H386.162Z"
      fill="#231F20"
    />
    <path
      d="M491.984 24.88C491.984 24.88 502.864 3.68 504.704 2.96C506.544 2.24 511.904 4.40001 511.504 5.52001C511.104 6.64001 508.865 16.8 508.865 16.8C508.865 16.8 514.705 13.04 515.345 14.56C515.985 16.08 508.784 23.68 508.784 23.68L499.024 39.92L491.984 24.88Z"
      fill="#D1D3D4"
    />
    <path
      d="M499.024 40.48C498.784 40.48 498.624 40.32 498.544 40.16L491.424 25.12C491.344 24.96 491.344 24.8 491.424 24.64C493.984 19.6 502.544 3.20003 504.464 2.40003C506.224 1.68003 510.304 3.12003 511.584 4.40003C511.984 4.72003 512.144 5.20004 512.064 5.68004C511.824 6.40004 510.464 12.16 509.744 15.6C511.424 14.64 513.984 13.28 515.184 13.68C515.504 13.76 515.744 14 515.904 14.32C516.544 15.92 512.064 21.12 509.264 24.08L499.504 40.24C499.424 40.4 499.264 40.48 499.024 40.48ZM492.624 24.88L499.104 38.64L508.304 23.36L508.384 23.28C511.584 19.84 514.944 15.6 514.784 14.72C514.304 14.48 511.664 15.68 509.184 17.28C509.024 17.44 508.704 17.44 508.544 17.28C508.384 17.12 508.224 16.88 508.304 16.64C508.384 16.24 510.464 6.80003 510.944 5.36003C510.464 4.88003 509.904 4.48003 509.184 4.16003C507.504 3.44003 505.664 3.04003 504.864 3.36003C503.744 4.16003 497.744 14.96 492.624 24.88Z"
      fill="#231F20"
    />
    <path
      d="M492.784 58.4001L504.624 31.7601L491.424 20.5601C491.424 20.5601 458.944 62.4001 472.544 73.1201L503.584 103.52L532.064 83.5201L492.784 58.4001Z"
      fill="#27AE60"
    />
    <path
      d="M529.824 40C529.824 40 531.024 33.92 537.584 29.68C544.144 25.44 562.384 28.24 565.664 35.68C568.944 43.12 559.264 62.56 559.264 62.56L528.144 70.96L529.824 40Z"
      fill="white"
    />
    <path
      d="M528.144 71.4401C527.824 71.4401 527.584 71.2001 527.584 70.8801C527.584 70.8801 527.584 70.8801 527.584 70.8001L529.264 39.9201V39.8401C529.344 39.6001 530.544 33.4401 537.344 29.1201C541.264 26.6401 549.184 26.3201 556.304 28.4801C561.424 30.0001 564.944 32.4801 566.224 35.3601C569.584 42.9601 560.224 61.9201 559.824 62.7201C559.744 62.8801 559.584 62.9601 559.424 63.0401L528.304 71.4401C528.224 71.4401 528.224 71.4401 528.144 71.4401ZM530.384 40.0801L528.784 70.1601L558.864 62.0801C559.904 59.8401 568.144 42.6401 565.104 35.9201C563.904 33.3601 560.624 31.0401 555.904 29.6801C549.184 27.6801 541.504 27.9201 537.904 30.2401C534.224 32.4001 531.584 35.9201 530.384 40.0801Z"
      fill="#231F20"
    />
    <path
      d="M557.744 128.56C559.984 111.84 561.424 95.3599 559.584 92.2399C553.264 81.4399 524.704 88.0799 519.424 90.4799C517.344 91.4399 518.624 108.32 515.264 128.64H557.744V128.56Z"
      fill="#231F20"
    />
    <path
      d="M584.136 129C584.136 129 589.89 100.526 581.18 92.0477C572.47 83.5694 551.614 84.8492 551.614 84.8492C551.614 84.8492 518.612 80.2901 508.463 85.489C504.308 87.6486 499.034 106.205 494 128.2H522.527L530.358 90.288L555.929 91.0879L547.538 128.28L584.136 129Z"
      fill="#27AE60"
    />
    <path
      d="M533.104 75.7601L530.864 90.6401C530.864 90.6401 531.904 100.48 541.824 99.2801C547.824 98.4801 553.104 95.0401 556.224 89.9201L559.264 62.5601C559.264 62.5601 552.544 57.9201 556.384 42.5601C547.424 43.0401 538.464 42.1601 529.744 40.0801C529.744 40.0801 524.464 59.6001 524.784 67.2801C525.104 74.9601 533.104 75.7601 533.104 75.7601Z"
      fill="#D1D3D4"
    />
    <path
      d="M540.224 99.9201C538.064 100 535.904 99.2801 534.224 98.0001C530.864 95.3601 530.384 90.8801 530.384 90.7201C530.384 90.6401 530.384 90.6401 530.384 90.5601L532.544 76.2401C530.624 75.8401 524.624 74.0001 524.304 67.2001C523.984 59.5201 529.104 40.6401 529.344 39.8401C529.424 39.5201 529.744 39.3601 530.064 39.4401C538.704 41.5201 547.584 42.3201 556.464 41.9201C556.624 41.9201 556.864 42.0001 556.944 42.0801C557.104 42.2401 557.104 42.4001 557.024 42.5601C553.264 57.2801 559.584 61.9201 559.664 62.0001C559.824 62.1601 559.904 62.3201 559.904 62.5601L556.864 89.9201C556.864 90.0001 556.864 90.0801 556.784 90.1601C553.584 95.4401 548.064 99.0401 541.984 99.8401C541.344 99.9201 540.784 99.9201 540.224 99.9201ZM531.504 90.6401C531.584 91.2001 532.144 94.8801 534.944 97.0401C536.704 98.4801 539.024 98.9601 541.824 98.6401C547.584 97.8401 552.624 94.5601 555.744 89.6801L558.704 62.7201C557.424 61.6001 552.544 56.4801 555.744 43.0401C547.184 43.3601 538.624 42.5601 530.304 40.6401C529.424 43.8401 525.184 60.3201 525.504 67.1201C525.824 74.1601 532.944 75.1201 533.264 75.1201C533.424 75.1201 533.584 75.2001 533.664 75.3601C533.744 75.5201 533.824 75.6801 533.744 75.7601L531.504 90.6401Z"
      fill="#231F20"
    />
    <path
      d="M531.984 83.52C531.984 83.52 535.745 84.72 540.545 81.12C545.345 77.52 545.184 75.04 545.184 75.04C545.184 75.04 539.344 77.76 533.184 75.76L531.984 83.52Z"
      fill="#231F20"
    />
    <path
      d="M533.584 84.2399C532.944 84.2399 532.384 84.1599 531.744 83.9999C531.504 83.9199 531.344 83.6799 531.344 83.3599L532.464 75.5999C532.464 75.4399 532.544 75.2799 532.704 75.1999C532.864 75.1199 533.024 75.1199 533.184 75.1199C539.104 77.0399 544.784 74.4799 544.784 74.4799C544.944 74.3999 545.104 74.3999 545.264 74.4799C545.424 74.5599 545.504 74.7199 545.504 74.9599C545.504 75.2799 545.504 77.7599 540.624 81.5199C538.784 83.1999 536.224 84.1599 533.584 84.2399ZM532.624 83.1199C533.744 83.2799 536.624 83.4399 540.224 80.7199C542.944 78.6399 543.984 76.9599 544.384 75.9999C540.944 77.1999 537.184 77.3599 533.584 76.4799L532.624 83.1199Z"
      fill="#231F20"
    />
    <path
      d="M542.064 54.0801C542.064 54.0801 536.544 62.0801 537.824 63.5201C539.104 64.9601 542.704 64.0801 542.704 64.0801"
      fill="#D1D3D4"
    />
    <path
      d="M540.384 64.8801C539.264 64.9601 538.224 64.6401 537.344 63.8401C535.904 62.2401 539.824 56.2401 541.504 53.7601C541.664 53.5201 542.064 53.4401 542.304 53.6001C542.544 53.7601 542.624 54.1601 542.464 54.4001C539.744 58.4001 537.664 62.4801 538.144 63.1201C538.944 64.0801 541.584 63.6801 542.464 63.4401C542.784 63.3601 543.104 63.5201 543.184 63.8401C543.264 64.1601 543.104 64.4801 542.784 64.5601C541.984 64.7201 541.184 64.8801 540.384 64.8801Z"
      fill="#231F20"
    />
    <path
      d="M541.184 70.3201H540.784C536.144 70.1601 532.864 66.4801 532.704 66.3201C532.464 66.0801 532.464 65.7601 532.704 65.5201C532.944 65.2801 533.264 65.3601 533.504 65.5201C533.504 65.5201 536.704 68.9601 540.784 69.1201C543.264 69.1201 545.584 68.1601 547.264 66.4001C547.504 66.1601 547.824 66.1601 548.064 66.4001C548.304 66.6401 548.304 66.9601 548.064 67.2001C546.304 69.2001 543.824 70.3201 541.184 70.3201Z"
      fill="#231F20"
    />
    <path
      d="M549.423 53.0028C549.471 51.7666 549.08 50.7478 548.55 50.7274C548.02 50.7069 547.552 51.6925 547.504 52.9288C547.457 54.165 547.847 55.1838 548.377 55.2043C548.907 55.2247 549.375 54.2391 549.423 53.0028Z"
      fill="#231F20"
    />
    <path
      d="M535.56 51.4963C535.608 50.26 535.217 49.2412 534.687 49.2208C534.157 49.2003 533.689 50.186 533.642 51.4222C533.594 52.6585 533.985 53.6772 534.515 53.6977C535.044 53.7181 535.512 52.7325 535.56 51.4963Z"
      fill="#231F20"
    />
    <path
      d="M553.504 57.8401C554.544 56.4801 555.824 55.2801 557.264 54.4001C558.064 53.8401 558.944 53.6001 559.904 53.6001C560.624 53.6801 561.344 54.0001 561.984 54.4801C563.824 55.8401 564.944 57.8401 565.184 60.1601C565.264 61.2001 565.104 62.2401 564.784 63.2001C564.064 64.6401 562.864 65.8401 561.344 66.4801C559.824 67.1201 558.304 67.6001 556.704 67.9201"
      fill="#D1D3D4"
    />
    <path
      d="M556.624 68.4001C556.304 68.4001 556.064 68.1601 555.984 67.8401C555.984 67.5201 556.144 67.2801 556.464 67.2001C557.984 66.8801 559.504 66.4801 560.944 65.7601C562.304 65.1201 563.424 64.0802 564.064 62.7202C564.384 61.8402 564.544 60.8801 564.464 60.0001C564.224 57.9201 563.184 56.0001 561.504 54.8001C561.024 54.4001 560.384 54.1602 559.744 54.0802C558.944 54.0802 558.144 54.4001 557.504 54.8001C556.144 55.6801 554.864 56.8002 553.904 58.0802C553.744 58.3202 553.344 58.4001 553.104 58.2401C552.864 58.0801 552.784 57.6801 553.024 57.4401C554.064 56.0001 555.424 54.8001 556.944 53.8401C557.824 53.2801 558.864 52.8801 559.904 52.9601C560.784 53.0401 561.584 53.3602 562.304 53.9202C564.224 55.3602 565.504 57.6001 565.744 60.0001C565.824 61.1201 565.664 62.2402 565.264 63.2802C564.464 64.8802 563.184 66.1602 561.584 66.8802C560.064 67.6002 558.464 68.0801 556.864 68.4001H556.624Z"
      fill="#231F20"
    />
    <path d="M576.624 112.32C573.744 116.08 569.824 121.92 565.664 128.56H576.624V112.32Z" fill="#27AE60" />
    <path
      d="M565.665 128.12C565.345 128.12 565.104 127.88 565.104 127.56C565.104 127.48 565.104 127.4 565.184 127.32L576.145 102.28C576.305 101.96 576.625 101.88 576.945 101.96C577.265 102.12 577.345 102.44 577.265 102.68L566.304 127.72C566.144 127.96 565.905 128.12 565.665 128.12Z"
      fill="#231F20"
    />
    <path
      d="M500.064 20.5601C499.904 20.5601 499.824 20.5601 499.744 20.4801C499.504 20.3201 499.424 19.9201 499.664 19.6801C503.184 14.9601 508.784 16.2401 509.024 16.2401C509.344 16.3201 509.504 16.5601 509.504 16.8801C509.424 17.2001 509.184 17.3601 508.864 17.3601C508.864 17.3601 508.864 17.3601 508.784 17.3601C508.544 17.2801 503.584 16.2401 500.624 20.3201C500.464 20.4801 500.224 20.5601 500.064 20.5601Z"
      fill="#231F20"
    />
    <line x1="230" y1="128.5" x2="635" y2="128.5" stroke="black" />
  </svg>
);
