export const DocGenerate = () => (
  <svg width="36" height="41" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.59961C0 1.49504 0.89543 0.599609 2 0.599609H24C25.1046 0.599609 26 1.49504 26 2.59961V6.59961H34C35.1046 6.59961 36 7.49501 36 8.59961V38.5996C36 39.7042 35.1046 40.5996 34 40.5996H10C8.8954 40.5996 8 39.7042 8 38.5996V34.5996H2C0.89543 34.5996 0 33.7042 0 32.5996V2.59961ZM22 6.59961H10C8.8954 6.59961 8 7.49501 8 8.59961V30.5996H4V4.59961H22V6.59961ZM29 17.5996V21.5996H15V17.5996H29ZM29 29.5996V25.5996H15V29.5996H29Z"
      fill="#7EDF9F"
    />
  </svg>
);

export const Rocket = () => (
  <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7147 12.2375C27.0062 6.81644 38.264 5.00945 41.9522 5.11042C42.5877 5.12782 42.9984 5.20185 43.1204 5.3238C43.9846 6.18804 42.4424 21.558 35.3878 28.6127C30.4141 33.5864 26.7474 35.5117 23.9251 35.8328L17.6647 36.4364L12.0078 30.7795C12.0078 30.7795 12.0937 27.4941 12.5955 25.0676C12.6455 24.826 12.6996 24.5928 12.7581 24.3723C14.0955 19.3354 15.0125 17.8752 19.8315 13.0563C20.1131 12.7747 20.408 12.5018 20.7147 12.2375ZM33.9279 18.7587C32.7564 19.9303 30.8569 19.9303 29.6853 18.7587C28.5137 17.5871 28.5137 15.6876 29.6853 14.5161C30.8569 13.3445 32.7564 13.3445 33.9279 14.5161C35.0995 15.6876 35.0995 17.5871 33.9279 18.7587Z"
      fill="#7EDF9F"
    />
    <path
      d="M23.6572 38.8724L24.2387 38.8164L24.2643 38.8134C27.8052 38.4106 31.7141 36.2449 36.3267 31.8845C35.9337 37.0761 32.6791 42.8249 24.578 44.5945L23.6572 38.8724Z"
      fill="#7EDF9F"
    />
    <path
      d="M3.60449 23.6206C5.43051 15.2612 11.4936 12.0622 16.8094 11.843C14.8833 13.804 13.49 15.372 12.4457 17.0418C11.2035 19.0279 10.5485 21.0038 9.85858 23.6025C9.77446 23.9193 9.69902 24.2513 9.63133 24.5905L3.60449 23.6206Z"
      fill="#7EDF9F"
    />
    <path
      d="M5.16967 36.701C5.54791 33.7922 8.2339 32.1686 10.9458 32.7346C10.1649 34.0745 10.3486 35.822 11.4968 36.9701C12.6924 38.1657 14.5378 38.3155 15.8962 37.4195C16.6854 40.2463 15.0496 43.1521 12.0153 43.5466L4.14648 44.5698L5.16967 36.701Z"
      fill="#7EDF9F"
    />
  </svg>
);

export const Check = () => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40.5996C31.0457 40.5996 40 31.6453 40 20.5996C40 9.55391 31.0457 0.599609 20 0.599609C8.9543 0.599609 0 9.55391 0 20.5996C0 31.6453 8.9543 40.5996 20 40.5996ZM17.375 29.4145L31.9074 15.0205L29.0925 12.1786L17.375 23.7846L11.9074 18.3691L9.0925 21.211L17.375 29.4145Z"
      fill="#7EDF9F"
    />
  </svg>
);

export const LawyerClient = () => (
  <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.0003 5.59961C27.8661 5.59961 31 8.63015 31 12.3685C31 12.6169 30.9859 12.8651 30.9577 13.1119L30.5059 17.0653C30.1395 20.2724 27.3369 22.6996 24.0003 22.6996C20.6637 22.6996 17.8612 20.2724 17.4947 17.0653L17.043 13.1119C16.6184 9.39619 19.3891 6.05114 23.2315 5.64056C23.4868 5.61328 23.7435 5.59961 24.0003 5.59961Z"
      fill="#7EDF9F"
    />
    <path
      d="M10 35.2268C10 29.529 15 25.5496 24 25.5496C33 25.5496 38 29.529 38 35.2268V43.5996H10V35.2268Z"
      fill="#7EDF9F"
    />
    <path
      d="M14.0622 13.4525C13.9551 12.5156 13.9861 11.5995 14.1383 10.7217C13.7715 10.6417 13.3907 10.5996 13 10.5996C12.8044 10.5996 12.609 10.6104 12.4145 10.6319C9.48834 10.9561 7.37833 13.5969 7.70166 16.5304L8.04568 19.6515C8.32475 22.1835 10.459 24.0996 13 24.0996C14.4744 24.0996 15.8119 23.4544 16.7294 22.4181C15.5373 21.0531 14.7343 19.335 14.5139 17.4059L14.0622 13.4525Z"
      fill="#7EDF9F"
    />
    <path
      d="M46.0002 40.5997H41.0002V35.2269C41.0002 31.7261 39.5573 28.6578 36.918 26.4321C42.7783 26.9549 46.0002 29.9159 46.0002 33.9896V40.5997Z"
      fill="#7EDF9F"
    />
    <path
      d="M31.2705 22.4183C32.4626 21.0532 33.2657 19.3351 33.4861 17.4059L33.9379 13.4525C33.979 13.0926 33.9996 12.7307 33.9996 12.3685C33.9996 11.8062 33.9516 11.2565 33.8596 10.7228C34.0409 10.6833 34.2259 10.6528 34.4143 10.6319C34.6088 10.6104 34.8042 10.5996 34.9998 10.5996C37.9438 10.5996 40.3303 12.9921 40.3303 15.9435C40.3303 16.1396 40.3196 16.3355 40.2981 16.5304L39.9541 19.6515C39.675 22.1835 37.5408 24.0996 34.9998 24.0996C33.5254 24.0996 32.188 23.4545 31.2705 22.4183Z"
      fill="#7EDF9F"
    />
    <path
      d="M7 35.2269C7 31.7261 8.44285 28.6578 11.0822 26.4321C5.22191 26.9549 2 29.9159 2 33.9896V40.5997H7V35.2269Z"
      fill="#7EDF9F"
    />
  </svg>
);

export const Diamond = () => (
  <svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3829 0.599609C30.9667 0.599609 31.5214 0.854739 31.9014 1.29803L42.124 13.2244C42.793 14.005 42.7614 15.1656 42.0508 15.9085L22.0254 36.8441C21.6316 37.2558 20.9739 37.2558 20.5801 36.8441L0.554724 15.9085C-0.155876 15.1656 -0.187536 14.005 0.481494 13.2244L10.7041 1.29803C11.0841 0.854739 11.6388 0.599609 12.2226 0.599609H30.3829ZM21.3027 24.5996L11.8398 14.5996H17.3468L21.3027 18.78L25.2586 14.5996H30.7656L21.3027 24.5996Z"
      fill="#7EDF9F"
    />
  </svg>
);

export const DataSecurity = () => (
  <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.2032 3.9456C23.7115 3.72484 24.2885 3.72484 24.7968 3.9456L35.9717 8.79933L26.5867 18.1843C25.8035 17.8095 24.9262 17.5996 24 17.5996C20.6863 17.5996 18 20.2859 18 23.5996C18 26.9133 20.6863 29.5996 24 29.5996C27.3137 29.5996 30 26.9133 30 23.5996C30 22.6733 29.7901 21.7959 29.4152 21.0126L39.9155 10.5123L40.7968 10.8951C41.5274 11.2124 42 11.9318 42 12.7283V23.145C42 38.0845 29.7282 45.2498 24.3789 46.5172C24.1268 46.577 23.8732 46.577 23.6211 46.5172C18.2718 45.2498 6 38.0845 6 23.145V12.7283C6 11.9318 6.47264 11.2124 7.20323 10.8951L23.2032 3.9456Z"
      fill="#7EDF9F"
    />
  </svg>
);
