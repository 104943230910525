export const EditForm = () => (
  <svg width="200" height="140" viewBox="0 0 200 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_961_18016)">
      <path
        d="M155.1 14.9L154.3 20.7L135.5 153.1H44.25L63.05 20.75L63.85 14.95C64.5 11.05 67.8 8.15003 71.8 8.05003H149.2C153 8.00003 155.7 11.1 155.1 14.9Z"
        fill="white"
      />
      <path
        d="M135.5 153.45H44.2504C44.0504 153.45 43.9004 153.3 43.9004 153.1V153.05L63.5004 14.8499C64.1504 10.7499 67.6504 7.74987 71.7504 7.59987H149.15C151 7.54987 152.8 8.34987 154 9.74987C155.25 11.1999 155.75 13.0999 155.45 14.9499L154.65 20.7499L135.85 153.1C135.85 153.3 135.7 153.45 135.5 153.45ZM44.6504 152.75H135.2L154.75 14.8499C155.05 13.1999 154.55 11.4999 153.5 10.1999C152.4 8.94987 150.8 8.29987 149.15 8.29987H71.8004C68.0004 8.39987 64.8504 11.1999 64.2504 14.9499L63.4504 20.7499L44.6504 152.75Z"
        fill="#231F20"
      />
      <path
        d="M102.951 76.6499H62.9008C62.7008 76.6499 62.5508 76.4999 62.5508 76.2999V76.2499L68.4508 34.6499C68.4508 34.4999 68.6008 34.3499 68.8008 34.3499H108.851C109.051 34.3499 109.201 34.4999 109.201 34.6999V34.7499L103.301 76.3499C103.251 76.5499 103.101 76.6499 102.951 76.6499ZM63.3008 75.9499H102.601L108.401 34.9999H69.1008L63.3008 75.9499Z"
        fill="#D1D3D4"
      />
      <path
        d="M140.951 35.0499H119.551C119.351 35.0499 119.201 34.8999 119.201 34.6999C119.201 34.4999 119.351 34.3499 119.551 34.3499H140.951C141.151 34.3499 141.301 34.4999 141.301 34.6999C141.301 34.8999 141.151 35.0499 140.951 35.0499Z"
        fill="#D1D3D4"
      />
      <path
        d="M140.201 40.5499H118.801C118.601 40.5499 118.451 40.3999 118.451 40.1999C118.451 39.9999 118.601 39.8499 118.801 39.8499H140.201C140.401 39.8499 140.551 39.9999 140.551 40.1999C140.501 40.3999 140.351 40.5499 140.201 40.5499Z"
        fill="#D1D3D4"
      />
      <path
        d="M139.4 46.1H118C117.8 46.1 117.65 45.95 117.65 45.75C117.65 45.55 117.8 45.4 118 45.4H139.4C139.6 45.4 139.75 45.55 139.75 45.75C139.75 45.95 139.55 46.1 139.4 46.1Z"
        fill="#D1D3D4"
      />
      <path
        d="M138.6 51.6501H117.2C117 51.6501 116.85 51.5001 116.85 51.3001C116.85 51.1001 117 50.9501 117.2 50.9501H138.6C138.8 50.9501 138.95 51.1001 138.95 51.3001C138.95 51.5001 138.8 51.6501 138.6 51.6501Z"
        fill="#D1D3D4"
      />
      <path
        d="M137.801 57.1999H116.401C116.201 57.1999 116.051 57.0499 116.051 56.8499C116.051 56.6499 116.201 56.4999 116.401 56.4999H137.801C138.001 56.4999 138.151 56.6499 138.151 56.8499C138.151 57.0499 138.001 57.1999 137.801 57.1999Z"
        fill="#D1D3D4"
      />
      <path
        d="M130.851 62.7499H115.651C115.451 62.7499 115.301 62.5999 115.301 62.3999C115.301 62.1999 115.451 62.0499 115.651 62.0499H130.851C131.051 62.0499 131.201 62.1999 131.201 62.3999C131.201 62.5999 131.051 62.7499 130.851 62.7499Z"
        fill="#D1D3D4"
      />
      <path
        d="M133.85 76.3001H114.9C113.05 76.3501 111.55 74.9001 111.5 73.0501C111.5 72.8001 111.5 72.6001 111.55 72.3501C111.9 70.1501 113.8 68.5001 116.05 68.4001H135C136.85 68.3501 138.35 69.8001 138.4 71.6501C138.4 71.9001 138.4 72.1001 138.35 72.3501C137.95 74.6001 136.1 76.2501 133.85 76.3001Z"
        fill="#D1D3D4"
      />
      <path
        d="M80.5512 131.05H61.6012C59.7512 131.1 58.2512 129.65 58.2012 127.8C58.2012 127.55 58.2012 127.35 58.2512 127.1C58.6012 124.9 60.5012 123.25 62.7512 123.15H81.7012C83.5512 123.1 85.0512 124.55 85.1012 126.4C85.1012 126.65 85.1012 126.85 85.0512 127.1C84.7012 129.35 82.8012 131 80.5512 131.05Z"
        fill="#D1D3D4"
      />
      <path
        d="M123.5 131.05H104.55C102.7 131.1 101.2 129.65 101.15 127.8C101.15 127.55 101.15 127.35 101.2 127.1C101.55 124.9 103.45 123.25 105.7 123.15H124.6C126.45 123.1 127.95 124.55 128 126.4C128 126.65 128 126.85 127.95 127.1C127.6 129.3 125.75 130.95 123.5 131.05Z"
        fill="#D1D3D4"
      />
      <path
        d="M88.9504 118.15H57.0004C56.8004 118.15 56.6504 118 56.6504 117.8V117.75L60.9004 88.0001C60.9004 87.8501 61.0504 87.7001 61.2504 87.7001H93.2004C93.4004 87.7001 93.5504 87.8501 93.5504 88.0501V88.1001L89.3504 117.9C89.2504 118.05 89.1004 118.15 88.9504 118.15ZM57.4004 117.45H88.6004L92.7004 88.3501H61.5004L57.4004 117.45Z"
        fill="#D1D3D4"
      />
      <path
        d="M131.851 118.15H99.9008C99.7008 118.15 99.5508 118 99.5508 117.8V117.75L103.801 88.0001C103.801 87.8501 103.951 87.7001 104.151 87.7001H136.101C136.301 87.7001 136.451 87.8501 136.451 88.0501V88.1001L132.201 117.9C132.201 118.05 132.051 118.15 131.851 118.15ZM100.351 117.45H131.601L135.701 88.3501H104.451L100.351 117.45Z"
        fill="#D1D3D4"
      />
      <path
        d="M81.8509 143.05C81.4009 143.05 81.0009 142.85 80.7509 142.55C80.4509 142.2 80.3509 141.8 80.4009 141.35C80.5509 140.45 81.3009 139.75 82.2009 139.75C82.6509 139.75 83.051 139.95 83.301 140.25C83.601 140.6 83.7009 141 83.6509 141.45C83.5009 142.35 82.7509 143.05 81.8509 143.05ZM82.2009 140.45C81.6509 140.5 81.151 140.9 81.051 141.45C81.001 141.7 81.0509 141.9 81.2009 142.1C81.3509 142.25 81.551 142.35 81.801 142.35C82.351 142.3 82.8509 141.9 82.9509 141.35C83.0009 141.1 82.951 140.9 82.801 140.7C82.651 140.55 82.4009 140.45 82.2009 140.45Z"
        fill="#D1D3D4"
      />
      <path
        d="M89.4512 142.28C90.017 141.753 90.1094 140.933 89.6577 140.448C89.206 139.963 88.3811 139.997 87.8154 140.524C87.2496 141.051 87.1572 141.871 87.6089 142.356C88.0607 142.841 88.8855 142.807 89.4512 142.28Z"
        fill="#D1D3D4"
      />
      <path
        d="M96.0977 142.27C96.6635 141.743 96.7559 140.923 96.3042 140.438C95.8524 139.953 95.0276 139.987 94.4619 140.514C93.8961 141.041 93.8037 141.861 94.2554 142.346C94.7071 142.831 95.532 142.797 96.0977 142.27Z"
        fill="#D1D3D4"
      />
      <path
        d="M102.705 142.294C103.271 141.767 103.363 140.946 102.912 140.461C102.46 139.976 101.635 140.011 101.069 140.538C100.504 141.065 100.411 141.885 100.863 142.37C101.315 142.855 102.139 142.821 102.705 142.294Z"
        fill="#D1D3D4"
      />
      <path
        d="M77.0504 24.9001H71.7504C71.5504 24.9001 71.4004 24.7501 71.4004 24.5501C71.4004 24.3501 71.5504 24.2001 71.7504 24.2001H77.0504C77.2504 24.2001 77.4004 24.3501 77.4004 24.5501C77.4004 24.7501 77.2504 24.9001 77.0504 24.9001Z"
        fill="#D1D3D4"
      />
      <path
        d="M76.7008 27.2499H71.4008C71.2008 27.2499 71.0508 27.0999 71.0508 26.8999C71.0508 26.6999 71.2008 26.5499 71.4008 26.5499H76.7008C76.9008 26.5499 77.0508 26.6999 77.0508 26.8999C77.0508 27.0999 76.9008 27.2499 76.7008 27.2499Z"
        fill="#D1D3D4"
      />
      <path
        d="M76.3512 29.6501H71.0512C70.8512 29.6501 70.7012 29.5001 70.7012 29.3001C70.7012 29.1001 70.8512 28.9501 71.0512 28.9501H76.3512C76.5512 28.9501 76.7012 29.1001 76.7012 29.3001C76.7012 29.5001 76.5512 29.6501 76.3512 29.6501Z"
        fill="#D1D3D4"
      />
      <path
        d="M155.101 14.8999L154.301 20.6999H63.0508L63.8508 14.8999C64.5008 10.9999 67.8008 8.09988 71.8008 7.99988H149.201C153.001 7.99988 155.701 11.0999 155.101 14.8999Z"
        fill="#231F20"
      />
      <path
        d="M111.701 14.6999H101.651C101.451 14.6999 101.301 14.5499 101.301 14.3499C101.301 14.1499 101.451 13.9999 101.651 13.9999H111.701C111.901 13.9999 112.051 14.1499 112.051 14.3499C112.001 14.5499 111.851 14.6999 111.701 14.6999Z"
        fill="#D1D3D4"
      />
      <path
        d="M116.75 14.6999H115C114.8 14.6999 114.65 14.5499 114.65 14.3499C114.65 14.1499 114.8 13.9999 115 13.9999H116.8C117 13.9999 117.15 14.1499 117.15 14.3499C117.15 14.5499 116.95 14.6999 116.75 14.6999Z"
        fill="#D1D3D4"
      />
      <path
        d="M49.0004 80.45L55.5004 87.2C55.5004 87.2 67.3004 74.35 67.4004 72.75C67.6504 70.05 68.7004 66.9 69.8004 64.4C69.8504 64.25 69.9504 64.1 70.1004 64C70.4504 63.85 70.7504 64.4 70.8004 64.75C70.9004 65.7 70.9004 66.65 70.7504 67.65C71.9004 66.05 73.1004 64.45 74.3504 62.85C74.4504 62.7 74.6004 62.6 74.8004 62.55C74.9504 62.55 75.0504 62.6 75.1504 62.7C75.9004 63.35 76.5004 64.2 76.8504 65.15C77.0504 65.65 77.2004 66.2 77.2004 66.75C77.2504 69.85 74.5004 74.85 71.6004 76.15C71.6004 76.15 58.2004 99.1 55.8004 98.85C53.4004 98.6 43.9004 87.85 43.9004 87.85L49.0004 80.45Z"
        fill="white"
      />
      <path
        d="M55.8004 99.15H55.7504C53.3004 98.85 44.6004 89.15 43.6004 88.05C43.5004 87.95 43.5004 87.75 43.5504 87.6L48.7004 80.2C48.7504 80.1 48.8504 80.05 48.9504 80.05C49.0504 80.05 49.1504 80.1 49.2504 80.15L55.5004 86.6C60.0504 81.65 67.0004 73.7 67.0504 72.6C67.3504 69.35 68.7004 65.9 69.4504 64.15C69.5504 63.9 69.7004 63.7 69.9504 63.55C70.1504 63.45 70.3504 63.5 70.5504 63.6C70.8504 63.85 71.0504 64.2 71.1004 64.6C71.1504 65.15 71.2004 65.75 71.1504 66.3C72.1004 65 73.0504 63.75 74.0004 62.5C74.1504 62.25 74.4004 62.1 74.7004 62.05C74.9004 62.05 75.1504 62.15 75.3004 62.3C76.1004 63 76.7504 63.9 77.1004 64.9C77.3004 65.45 77.4504 66.05 77.5004 66.6C77.5504 69.7 74.9004 74.8 71.8004 76.25C69.4504 80.45 58.3504 99.15 55.8004 99.15ZM44.3004 87.8C48.1004 92.05 54.1504 98.3 55.8004 98.45H55.8504C57.3004 98.45 64.8004 87.05 71.3004 75.95C71.3504 75.9 71.4004 75.85 71.4504 75.8C74.3004 74.55 76.9004 69.65 76.8504 66.75C76.8504 66.25 76.7004 65.75 76.5004 65.25C76.2004 64.35 75.6504 63.55 74.9504 62.95C74.8504 62.9 74.8004 62.85 74.8004 62.85C74.8004 62.85 74.7504 62.9 74.6504 63C73.4504 64.55 72.2504 66.2 71.0504 67.8C70.9504 67.95 70.8004 68 70.6504 67.9C70.5004 67.85 70.4004 67.7 70.4504 67.5C70.6004 66.6 70.6004 65.65 70.5004 64.7C70.5004 64.5 70.4004 64.35 70.3004 64.2C70.2504 64.25 70.2004 64.35 70.2004 64.4C69.4504 66.1 68.1004 69.5 67.8504 72.65C67.7004 74.3 57.8004 85.15 55.8504 87.3C55.8004 87.35 55.7004 87.4 55.6004 87.4C55.5004 87.4 55.4004 87.35 55.3504 87.3L49.0004 81L44.3004 87.8Z"
        fill="#231F20"
      />
      <path
        d="M23.2995 58.05C22.3495 58 21.3995 57.85 20.2995 57.75C19.2495 57.65 17.9495 57.3 17.9495 56.25C17.9495 55.35 18.9995 54.65 18.7495 53.8C18.6495 53.55 18.5495 53.35 18.3995 53.2C15.3495 49.1 16.0495 43.35 19.9995 40.15C19.6995 40.2 19.4495 40 19.3995 39.7V39.65C19.3995 39.35 19.4995 39.05 19.7495 38.8C20.4995 37.95 21.4995 37.35 22.5995 37.05C26.0495 36 29.7995 36 33.2495 37.05C35.8995 37.85 39.0995 39.95 38.7495 43.15C38.6495 44.3 37.6995 45.45 36.5495 45.5C35.9495 48.95 34.1495 51.3 31.8995 53.8C30.3995 55.5 28.4495 56.8 26.2995 57.6C25.2995 57.95 24.2995 58.1 23.2995 58.05Z"
        fill="white"
      />
      <path
        d="M23.7507 58.4C23.6007 58.4 23.4507 58.4 23.3007 58.4C22.7507 58.35 22.2007 58.3 21.6007 58.25C21.2007 58.2 20.7507 58.15 20.3007 58.1C18.6507 57.95 17.6507 57.25 17.6007 56.25C17.6507 55.75 17.8007 55.3 18.1007 54.9C18.3507 54.5 18.5507 54.2 18.4507 53.85C18.4007 53.7 18.3007 53.55 18.2007 53.4C15.1507 49.35 15.6507 43.65 19.4007 40.25C19.2507 40.1 19.1507 39.9 19.1007 39.65C19.1007 39.25 19.2507 38.85 19.5007 38.55C20.3007 37.65 21.3507 37 22.5007 36.7C26.0507 35.6 29.8007 35.6 33.3507 36.7C36.4507 37.6 39.4507 40 39.1007 43.2C38.9507 44.35 38.1007 45.6 36.8507 45.8C36.1507 49.35 34.1507 51.8 32.1507 54C30.6007 55.75 28.6007 57.1 26.4007 57.9C25.5507 58.25 24.6507 58.4 23.7507 58.4ZM19.9507 39.75C20.1007 39.75 20.2007 39.8 20.3007 39.95C20.4007 40.1 20.3507 40.3 20.2507 40.4C16.4507 43.5 15.7507 49.05 18.6507 52.95C18.8507 53.15 19.0007 53.4 19.0507 53.7C19.1507 54.25 19.0007 54.85 18.6507 55.3C18.4507 55.6 18.3007 55.9 18.2507 56.25C18.2507 57.2 19.9507 57.35 20.3007 57.4C20.7507 57.45 21.2007 57.5 21.6007 57.55C22.2007 57.6 22.7007 57.7 23.2507 57.7C24.2007 57.75 25.2007 57.6 26.1007 57.3C28.2007 56.55 30.0507 55.25 31.5507 53.6C34.0507 50.85 35.6007 48.65 36.1507 45.5C36.2007 45.35 36.3007 45.2 36.5007 45.2C37.5007 45.15 38.2507 44.1 38.3507 43.2C38.7007 40 35.2007 38.1 33.1007 37.45C29.7007 36.4 26.0507 36.4 22.6507 37.45C21.6007 37.7 20.7007 38.25 19.9507 39.05C19.8007 39.2 19.7007 39.4 19.7007 39.65C19.7007 39.7 19.7507 39.8 19.8007 39.8C19.9007 39.75 19.9507 39.75 19.9507 39.75Z"
        fill="#231F20"
      />
      <path
        d="M8.99962 78.7C8.99962 78.7 1.54962 93.75 2.14962 98.4C2.74962 103.05 18.0496 101.2 18.0496 101.2L17.2496 78.3L8.99962 78.7Z"
        fill="white"
      />
      <path
        d="M11.7502 101.9C11.3002 101.9 10.8002 101.9 10.3502 101.9C5.00019 101.7 2.15019 100.55 1.85019 98.45C1.25019 93.75 8.40019 79.2 8.70019 78.55C8.75019 78.45 8.85019 78.35 9.00019 78.35L17.2502 77.95C17.3502 77.95 17.4502 78 17.5002 78.05C17.5502 78.1 17.6002 78.2 17.6002 78.3L18.4002 101.2C18.4002 101.4 18.2502 101.55 18.1002 101.55C15.9502 101.8 13.8502 101.9 11.7502 101.9ZM9.25019 79.05C8.40019 80.8 2.00019 94.15 2.55019 98.35C3.00019 101.8 13.3502 101.4 17.7002 100.9L16.9002 78.65L9.25019 79.05Z"
        fill="#231F20"
      />
      <path
        d="M41.85 105.3L49 176.7L37.2 176.95L23.8 126.5L16.4 177.35L2.75 176.7C2.75 176.7 5.15 148.3 6.35 137.5C7.55 126.7 9.5 108 9.5 108L41.85 105.3Z"
        fill="#231F20"
      />
      <path d="M37.45 50.5C37.45 50.5 39.9 54.55 39.35 55.35C38.8 56.15 37 55.55 37 55.55" fill="white" />
      <path
        d="M38.2997 56.1C37.8497 56.1 37.3497 56 36.8997 55.85C36.6997 55.8 36.6497 55.55 36.6997 55.4C36.7497 55.25 36.9497 55.15 37.0997 55.2C37.5997 55.35 38.7497 55.6 39.0497 55.15C39.2497 54.8 38.3997 52.7 37.1497 50.7C37.0497 50.55 37.0997 50.3 37.2997 50.2C37.4497 50.1 37.6497 50.15 37.7497 50.3C38.5497 51.6 40.2497 54.65 39.5997 55.55C39.2997 55.95 38.7997 56.15 38.2997 56.1Z"
        fill="#231F20"
      />
      <path
        d="M37.1008 45.3C38.7008 46.7 38.1008 49.55 38.0508 51.45C37.9508 54.05 37.7508 56.7 37.5008 59.3C37.3508 60.95 33.0508 60.8 32.3008 60.3L32.7008 66.9L23.8008 63.1L24.4008 55.55C24.4008 55.55 27.2508 55.7 29.0508 52.35C30.8508 49 31.0008 43.8 31.0008 43.8C31.0008 43.8 35.5508 43.9 37.1008 45.3Z"
        fill="white"
      />
      <path
        d="M32.6992 67.2C32.6492 67.2 32.5992 67.2 32.5492 67.15L23.6492 63.35C23.4992 63.3 23.4492 63.15 23.4492 63L24.0492 55.45C24.0492 55.25 24.2492 55.1 24.3992 55.15C24.4992 55.15 27.0492 55.2 28.7492 52.15C30.4992 48.95 30.6492 43.85 30.6492 43.8C30.6492 43.7 30.6992 43.6 30.7492 43.55C30.7992 43.5 30.8992 43.45 30.9992 43.45C31.1992 43.45 35.6992 43.6 37.3492 45.05C38.7992 46.35 38.6492 48.6 38.4492 50.45C38.3992 50.8 38.3992 51.15 38.3992 51.45C38.2992 54.05 38.0992 56.7 37.8492 59.3C37.7492 59.9 37.3492 60.35 36.7992 60.55C35.4492 61.05 34.0492 61.1 32.6492 60.8L33.0492 66.85C33.0492 66.95 32.9992 67.1 32.8992 67.15C32.8492 67.15 32.7992 67.2 32.6992 67.2ZM24.1992 62.8L32.3492 66.3L31.9492 60.3C31.9492 60.1 32.0992 59.95 32.2992 59.95C32.3992 59.95 32.4492 59.95 32.4992 60C32.8992 60.3 35.2492 60.5 36.4992 59.9C36.8992 59.7 37.0992 59.5 37.0992 59.25C37.3492 56.65 37.5492 54 37.6492 51.4C37.6492 51.1 37.6992 50.75 37.6992 50.35C37.8492 48.65 37.9992 46.6 36.7992 45.55C35.5992 44.45 32.2992 44.2 31.2492 44.15C31.1992 45.4 30.8492 49.6 29.2992 52.5C27.7492 55.4 25.4992 55.8 24.6492 55.85L24.1992 62.8Z"
        fill="#231F20"
      />
      <path
        d="M50.8008 81C50.8008 81 40.2508 69.85 36.1008 65.7C32.2508 61.8 20.9508 58.3 15.8508 63.85C15.8508 63.85 7.40078 75.6 5.80078 82.45L10.7008 85.45C9.60078 93.55 9.00078 101.7 8.80078 109.85C18.1008 117.2 41.8508 105.3 41.8508 105.3L39.7008 88.55L43.5508 92.4L50.8008 81Z"
        fill="#27AE60"
      />
      <path d="M37.5004 57.3C37.5004 57.3 35.4004 58.45 34.4004 56.35L37.5004 57.3Z" fill="white" />
      <path
        d="M36.2508 58C36.0008 58 35.8008 57.95 35.5508 57.9C34.9008 57.65 34.3508 57.15 34.1008 56.5C34.0008 56.3 34.1008 56.1 34.2508 56.05C34.4508 55.95 34.6508 56.05 34.7008 56.2C34.9008 56.65 35.2508 57 35.7008 57.2C36.2508 57.35 36.8008 57.25 37.2508 57C37.4008 56.9 37.6508 57 37.7008 57.15C37.7508 57.3 37.7008 57.5 37.5508 57.6C37.2008 57.85 36.7508 58 36.2508 58Z"
        fill="#231F20"
      />
      <path
        d="M32.4006 52C32.3506 51.35 32.1006 50.7 31.7506 50.2C31.5006 49.75 31.2006 49.35 30.8506 49C30.5006 48.65 29.9506 48.45 29.4506 48.5C29.0006 48.6 28.6006 48.9 28.4006 49.3C28.2006 49.7 28.0506 50.15 28.0006 50.6C27.8506 52.6 28.7506 54.55 30.4006 55.7C30.3506 55.65 30.3006 55.6 30.2506 55.55"
        fill="white"
      />
      <path
        d="M30.4003 55.95C30.3503 55.95 30.2503 55.95 30.2003 55.9C28.4503 54.7 27.4503 52.6 27.6503 50.5C27.7003 50 27.8503 49.5 28.1003 49.05C28.4003 48.55 28.8503 48.2 29.4003 48.1C30.0003 48 30.6503 48.25 31.1003 48.65C31.5003 49.05 31.8003 49.45 32.0503 49.95C32.4503 50.55 32.7003 51.25 32.7503 51.95C32.7503 52.15 32.6003 52.3 32.4003 52.3C32.2003 52.3 32.0503 52.15 32.0503 51.95C32.0003 51.35 31.8003 50.8 31.4503 50.3C31.2503 49.9 30.9503 49.5 30.6003 49.15C30.3003 48.85 29.9003 48.7 29.5003 48.75C29.1503 48.85 28.8503 49.05 28.7003 49.35C28.5003 49.7 28.4003 50.1 28.3503 50.5C28.2003 52.25 28.9503 53.95 30.3003 55.05C30.4003 55.05 30.4503 55.1 30.5503 55.15L30.7003 55.3C30.8003 55.45 30.8003 55.65 30.7003 55.75C30.5503 55.9 30.4503 55.95 30.4003 55.95Z"
        fill="#231F20"
      />
      <path
        d="M32.2996 60.25C32.2996 60.25 30.0996 59.45 29.0996 57.9C29.0996 57.9 29.0996 61.9 32.4496 63.2L32.2996 60.25Z"
        fill="#231F20"
      />
      <path
        d="M32.5008 63.55C32.4508 63.55 32.4008 63.55 32.4008 63.55C28.8508 62.2 28.8008 58 28.8008 57.95C28.8008 57.8 28.9008 57.65 29.0508 57.6C29.2008 57.55 29.3508 57.6 29.4508 57.75C30.3508 59.2 32.4508 59.95 32.4508 59.95C32.6008 60 32.6508 60.1 32.7008 60.25L32.9008 63.2C32.9008 63.3 32.8508 63.45 32.7508 63.5C32.6508 63.55 32.6008 63.6 32.5008 63.55ZM29.6008 59.05C29.8508 60.55 30.7508 61.9 32.1008 62.65L31.9508 60.5C31.1008 60.15 30.3008 59.65 29.6008 59.05Z"
        fill="#231F20"
      />
      <path
        d="M40.0006 89.7C39.9006 89.7 39.8006 89.65 39.7506 89.6C37.1006 86.85 34.5506 83.95 32.2006 80.9C32.1006 80.75 32.1006 80.55 32.2506 80.4C32.4006 80.25 32.6006 80.3 32.7506 80.45C35.1006 83.45 37.6006 86.35 40.2506 89.1C40.4006 89.25 40.4006 89.45 40.2506 89.6C40.2006 89.65 40.1006 89.7 40.0006 89.7Z"
        fill="#231F20"
      />
      <path
        d="M23.6008 125.5C23.5008 125.5 23.4508 125.45 23.4008 125.4C23.2008 125.25 19.0508 121.7 19.0508 116.1C19.0508 115.9 19.2008 115.75 19.4008 115.75C19.6008 115.75 19.7508 115.9 19.7508 116.1C19.7508 121.35 23.8008 124.85 23.8508 124.85C24.0008 124.95 24.0008 125.2 23.9008 125.35C23.8008 125.45 23.7008 125.5 23.6008 125.5Z"
        fill="white"
      />
      <path
        d="M36.3933 48.7181C36.5007 48.2046 36.4344 47.7562 36.2452 47.7166C36.056 47.6771 35.8155 48.0613 35.7081 48.5748C35.6007 49.0884 35.667 49.5368 35.8563 49.5763C36.0455 49.6159 36.2859 49.2317 36.3933 48.7181Z"
        fill="#231F20"
      />
      <path
        d="M10.6 83.4H10.55C10.35 83.35 10.25 83.2 10.25 83C10.35 82.5 12.3 70.45 14.5 66.4C14.6 66.25 14.8 66.15 14.95 66.25C15.1 66.35 15.2 66.55 15.1 66.7C12.95 70.6 10.95 82.95 10.9 83.05C10.95 83.25 10.8 83.4 10.6 83.4Z"
        fill="#231F20"
      />
      <path d="M173.799 100.95H139.549L147.299 43.35H181.499L173.799 100.95Z" fill="white" />
      <path
        d="M173.799 101.3H139.549C139.349 101.3 139.199 101.15 139.199 100.95C139.199 100.95 139.199 100.95 139.199 100.9L146.899 43.3C146.899 43.15 147.049 43 147.249 43H181.499C181.699 43 181.849 43.15 181.849 43.35C181.849 43.35 181.849 43.35 181.849 43.4L174.149 101C174.149 101.15 173.999 101.3 173.799 101.3ZM139.949 100.6H173.499L181.099 43.7H147.549L139.949 100.6Z"
        fill="#231F20"
      />
      <path
        d="M168 92.4H148.35C147.15 92.45 146.1 91.5 146.1 90.25C146.1 90.1 146.1 89.95 146.15 89.8L146.4 87.8C146.6 86.35 147.85 85.25 149.35 85.2H169C170.2 85.15 171.25 86.1 171.25 87.35C171.25 87.5 171.25 87.65 171.2 87.8L170.95 89.8C170.75 91.25 169.5 92.35 168 92.4Z"
        fill="#27AE60"
      />
      <path
        d="M169.449 81.8H149.799C148.599 81.85 147.549 80.9 147.549 79.65C147.549 79.5 147.549 79.35 147.599 79.2L147.849 77.2C148.049 75.75 149.299 74.65 150.799 74.6H170.449C171.649 74.55 172.699 75.5 172.699 76.75C172.699 76.9 172.699 77.05 172.649 77.2L172.399 79.2C172.149 80.7 170.899 81.75 169.449 81.8Z"
        fill="white"
      />
      <path
        d="M169.449 82.15H149.799C149.049 82.15 148.299 81.85 147.799 81.3C147.299 80.7 147.099 79.95 147.199 79.2L147.449 77.2C147.699 75.6 149.099 74.35 150.699 74.3H170.349C171.099 74.3 171.849 74.6 172.349 75.15C172.849 75.75 173.049 76.5 172.949 77.25L172.699 79.25C172.449 80.9 171.049 82.1 169.449 82.15ZM150.749 75C149.449 75.05 148.349 76 148.149 77.3L147.899 79.3C147.799 79.85 147.949 80.45 148.349 80.85C148.699 81.25 149.249 81.5 149.799 81.5H169.449C170.749 81.45 171.849 80.5 172.049 79.2L172.299 77.2C172.399 76.65 172.249 76.05 171.849 75.65C171.499 75.25 170.949 75 170.399 75H150.749Z"
        fill="#D1D3D4"
      />
      <path
        d="M170.849 71.25H151.199C149.999 71.3 148.949 70.35 148.949 69.1C148.949 68.95 148.949 68.8 148.999 68.65L149.249 66.65C149.449 65.2 150.699 64.1 152.199 64.05H171.849C173.049 64 174.099 64.95 174.099 66.2C174.099 66.35 174.099 66.5 174.049 66.65L173.799 68.65C173.549 70.1 172.299 71.2 170.849 71.25Z"
        fill="white"
      />
      <path
        d="M170.849 71.6H151.199C150.449 71.6 149.699 71.3 149.199 70.75C148.699 70.15 148.499 69.4 148.599 68.65L148.849 66.65C149.099 65.05 150.499 63.8 152.099 63.75H171.749C172.499 63.75 173.249 64.05 173.749 64.6C174.249 65.2 174.449 65.95 174.349 66.7L174.099 68.7C173.849 70.3 172.499 71.55 170.849 71.6ZM152.149 64.4C150.849 64.45 149.749 65.4 149.549 66.7L149.299 68.7C149.199 69.25 149.349 69.85 149.749 70.25C150.099 70.65 150.649 70.9 151.199 70.9H170.849C172.149 70.85 173.249 69.9 173.449 68.6L173.699 66.6C173.799 66.05 173.649 65.45 173.249 65.05C172.899 64.65 172.349 64.4 171.799 64.4H152.149Z"
        fill="#D1D3D4"
      />
      <path
        d="M172.25 60.65H152.6C151.4 60.7 150.35 59.75 150.35 58.5C150.35 58.35 150.35 58.2 150.4 58.05L150.65 56.05C150.85 54.6 152.1 53.5 153.6 53.45H173.25C174.45 53.4 175.5 54.35 175.5 55.6C175.5 55.75 175.5 55.9 175.45 56.05L175.2 58.05C175 59.5 173.75 60.6 172.25 60.65Z"
        fill="white"
      />
      <path
        d="M172.25 61H152.6C151.85 61 151.1 60.7 150.6 60.15C150.1 59.55 149.9 58.8 150 58.05L150.25 56.05C150.5 54.45 151.9 53.2 153.5 53.15H173.15C173.9 53.15 174.65 53.45 175.15 54C175.65 54.6 175.85 55.35 175.75 56.1L175.5 58.1C175.3 59.75 173.9 60.95 172.25 61ZM153.55 53.85C152.25 53.9 151.15 54.85 150.95 56.15L150.7 58.15C150.6 58.7 150.75 59.3 151.15 59.7C151.5 60.1 152.05 60.35 152.6 60.35H172.25C173.55 60.3 174.65 59.35 174.85 58.05L175.1 56.05C175.2 55.5 175.05 54.9 174.65 54.5C174.3 54.1 173.75 53.85 173.2 53.85H153.55Z"
        fill="#D1D3D4"
      />
      <path
        d="M171.499 49.1H156.399C156.199 49.1 156.049 48.95 156.049 48.75C156.049 48.55 156.199 48.4 156.399 48.4H171.499C171.699 48.4 171.849 48.55 171.849 48.75C171.849 48.95 171.699 49.1 171.499 49.1Z"
        fill="#D1D3D4"
      />
    </g>
    <defs>
      <clipPath id="clip0_961_18016">
        <rect width="200" height="140" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AiRefine = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(1.3)">
      <path
        d="M7.4967 4.08141C7.79742 3.17638 9.07758 3.17638 9.3783 4.08141L10.1359 6.36135C10.2316 6.64947 10.4541 6.87784 10.7396 6.98106L13.0462 7.81499C13.9186 8.1304 13.9186 9.36422 13.0462 9.67963L10.7396 10.5136C10.4541 10.6168 10.2316 10.8451 10.1359 11.1333L9.3783 13.4132C9.07758 14.3182 7.79742 14.3182 7.4967 13.4132L6.73913 11.1333C6.6434 10.8451 6.42092 10.6168 6.1354 10.5136L3.82877 9.67963C2.95636 9.36421 2.95636 8.1304 3.82877 7.81499L6.1354 6.98106C6.42092 6.87784 6.6434 6.64947 6.73913 6.36135L7.4967 4.08141Z"
        fill="#27AE60"
      />
      <path
        d="M13.5095 12.2197C13.6984 11.6871 14.4516 11.6871 14.6405 12.2197L14.9147 12.9928C14.9732 13.1578 15.1008 13.2889 15.264 13.352L16.0754 13.6653C16.5872 13.8629 16.5872 14.5871 16.0754 14.7847L15.264 15.098C15.1008 15.1611 14.9732 15.2922 14.9147 15.4572L14.6405 16.2303C14.4516 16.7629 13.6984 16.7629 13.5095 16.2303L13.2353 15.4572C13.1768 15.2922 13.0492 15.1611 12.886 15.098L12.0746 14.7847C11.5628 14.5871 11.5628 13.8629 12.0746 13.6653L12.886 13.352C13.0492 13.2889 13.1768 13.1578 13.2353 12.9928L13.5095 12.2197Z"
        fill="#27AE60"
      />
    </g>
  </svg>
);

export const AiRefineGray = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(1.3)">
      <path
        d="M7.4967 4.08141C7.79742 3.17638 9.07758 3.17638 9.3783 4.08141L10.1359 6.36135C10.2316 6.64947 10.4541 6.87784 10.7396 6.98106L13.0462 7.81499C13.9186 8.1304 13.9186 9.36422 13.0462 9.67963L10.7396 10.5136C10.4541 10.6168 10.2316 10.8451 10.1359 11.1333L9.3783 13.4132C9.07758 14.3182 7.79742 14.3182 7.4967 13.4132L6.73913 11.1333C6.6434 10.8451 6.42092 10.6168 6.1354 10.5136L3.82877 9.67963C2.95636 9.36421 2.95636 8.1304 3.82877 7.81499L6.1354 6.98106C6.42092 6.87784 6.6434 6.64947 6.73913 6.36135L7.4967 4.08141Z"
        fill="#9e9e9e"
      />
      <path
        d="M13.5095 12.2197C13.6984 11.6871 14.4516 11.6871 14.6405 12.2197L14.9147 12.9928C14.9732 13.1578 15.1008 13.2889 15.264 13.352L16.0754 13.6653C16.5872 13.8629 16.5872 14.5871 16.0754 14.7847L15.264 15.098C15.1008 15.1611 14.9732 15.2922 14.9147 15.4572L14.6405 16.2303C14.4516 16.7629 13.6984 16.7629 13.5095 16.2303L13.2353 15.4572C13.1768 15.2922 13.0492 15.1611 12.886 15.098L12.0746 14.7847C11.5628 14.5871 11.5628 13.8629 12.0746 13.6653L12.886 13.352C13.0492 13.2889 13.1768 13.1578 13.2353 12.9928L13.5095 12.2197Z"
        fill="#9e9e9e"
      />
    </g>
  </svg>
);

export const AiRefineWhite = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="scale(1.3)">
      <path
        d="M7.4967 4.08141C7.79742 3.17638 9.07758 3.17638 9.3783 4.08141L10.1359 6.36135C10.2316 6.64947 10.4541 6.87784 10.7396 6.98106L13.0462 7.81499C13.9186 8.1304 13.9186 9.36422 13.0462 9.67963L10.7396 10.5136C10.4541 10.6168 10.2316 10.8451 10.1359 11.1333L9.3783 13.4132C9.07758 14.3182 7.79742 14.3182 7.4967 13.4132L6.73913 11.1333C6.6434 10.8451 6.42092 10.6168 6.1354 10.5136L3.82877 9.67963C2.95636 9.36421 2.95636 8.1304 3.82877 7.81499L6.1354 6.98106C6.42092 6.87784 6.6434 6.64947 6.73913 6.36135L7.4967 4.08141Z"
        fill="#FFFFFF"
      />
      <path
        d="M13.5095 12.2197C13.6984 11.6871 14.4516 11.6871 14.6405 12.2197L14.9147 12.9928C14.9732 13.1578 15.1008 13.2889 15.264 13.352L16.0754 13.6653C16.5872 13.8629 16.5872 14.5871 16.0754 14.7847L15.264 15.098C15.1008 15.1611 14.9732 15.2922 14.9147 15.4572L14.6405 16.2303C14.4516 16.7629 13.6984 16.7629 13.5095 16.2303L13.2353 15.4572C13.1768 15.2922 13.0492 15.1611 12.886 15.098L12.0746 14.7847C11.5628 14.5871 11.5628 13.8629 12.0746 13.6653L12.886 13.352C13.0492 13.2889 13.1768 13.1578 13.2353 12.9928L13.5095 12.2197Z"
        fill="#FFFFFF"
      />
    </g>
  </svg>
);
