export const RocketIcon = ({ className }: { className?: string }) => (
  <svg width="212" height="177" viewBox="0 0 212 177" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.3398 115.8V120.758L86.7129 176.5L211.219 133.835V128.986L26.3398 115.8Z" fill="#231F20" />
    <path
      d="M86.7154 176.88C86.6064 176.88 86.5519 176.826 86.4429 176.771L26.0699 121.029C26.0154 120.975 25.9609 120.866 25.9609 120.757V115.853C25.9609 115.744 26.0154 115.635 26.0699 115.581C26.1244 115.526 26.2334 115.472 26.3424 115.472L211.221 128.658C211.439 128.658 211.603 128.821 211.548 129.039V133.889C211.548 134.052 211.439 134.216 211.276 134.27L86.8243 176.88C86.8243 176.88 86.7699 176.88 86.7154 176.88ZM26.6693 120.594L86.8243 176.063L210.84 133.616V129.312L26.7238 116.235V120.594H26.6693Z"
      fill="#231F20"
    />
    <path d="M26.3425 115.8L0.351562 26.7114L128.399 0.393555L146.653 78.8023L26.3425 115.8Z" fill="white" />
    <path
      d="M26.3404 116.181C26.2859 116.181 26.2314 116.181 26.1769 116.126C26.068 116.072 26.0135 116.017 26.0135 115.908L0.02257 26.8199C-0.0319183 26.7109 0.0225691 26.602 0.0770574 26.493C0.131546 26.384 0.240522 26.3295 0.349499 26.3295L128.397 0.0116646C128.615 -0.0428238 128.778 0.120641 128.833 0.284106L147.086 78.6928C147.141 78.9108 147.032 79.0742 146.814 79.1287L26.4494 116.126L26.3404 116.181ZM0.839894 26.9834L26.5584 115.309L146.215 78.5294L128.125 0.82899L0.839894 26.9834Z"
      fill="#231F20"
    />
    <path d="M146.65 78.8008L26.3398 115.798L86.8218 170.123L211.219 128.985L146.65 78.8008Z" fill="white" />
    <path
      d="M86.8223 170.506C86.7133 170.506 86.6588 170.452 86.5498 170.397L26.0679 116.072C25.9589 115.963 25.9044 115.854 25.9589 115.691C26.0134 115.582 26.0679 115.473 26.2313 115.419L146.541 78.421C146.65 78.3665 146.814 78.421 146.868 78.4755L211.437 128.659C211.546 128.768 211.6 128.877 211.6 129.041C211.546 129.204 211.491 129.313 211.328 129.313L86.9313 170.452C86.8768 170.506 86.8768 170.506 86.8223 170.506ZM27.0486 115.963L86.8768 169.689L210.402 128.823L146.596 79.1838L27.0486 115.963Z"
      fill="#231F20"
    />
    <path d="M144.69 85.7773L40.5625 119.724L68.2425 144.08L175.367 108.499L144.69 85.7773Z" fill="white" />
    <path
      d="M68.2431 144.46C68.1341 144.46 68.0796 144.406 67.9706 144.351L40.2906 119.995C40.1271 119.831 40.1271 119.614 40.2361 119.45C40.2906 119.396 40.3451 119.341 40.3996 119.341L144.527 85.4493C144.636 85.3948 144.745 85.4493 144.854 85.5038L175.585 108.225C175.694 108.334 175.748 108.443 175.748 108.607C175.748 108.77 175.639 108.879 175.476 108.879L68.3521 144.46C68.3521 144.406 68.2976 144.406 68.2431 144.46ZM41.3259 119.831L68.3521 143.588L174.55 108.334L144.581 86.2122L41.3259 119.831Z"
      fill="#D1D3D4"
    />
    <path d="M151.935 122.065L111.777 134.489L129.922 148.656L169.48 135.361L151.935 122.065Z" fill="white" />
    <path
      d="M129.924 149.037C129.815 149.037 129.761 148.983 129.706 148.983L111.507 134.816C111.398 134.707 111.344 134.598 111.344 134.434C111.344 134.271 111.453 134.162 111.616 134.162L151.829 121.738C151.938 121.684 152.046 121.738 152.155 121.793L169.646 135.088C169.755 135.197 169.81 135.306 169.81 135.469C169.81 135.633 169.701 135.742 169.537 135.742L130.033 149.037C130.033 149.037 129.979 149.037 129.924 149.037ZM112.597 134.652L130.033 148.274L168.665 135.251L151.883 122.501L112.597 134.652Z"
      fill="#D1D3D4"
    />
    <path d="M30.0444 108.498L6.94141 31.5602L123.819 6.60449L140.601 76.0771L30.0444 108.498Z" fill="white" />
    <path
      d="M30.0461 108.879C29.8826 108.879 29.7192 108.77 29.6647 108.606L6.56163 31.6687C6.50714 31.5597 6.56163 31.4507 6.61612 31.3417C6.67061 31.2327 6.77958 31.1783 6.88856 31.1783L123.766 6.2226C123.984 6.16811 124.147 6.33158 124.202 6.49504L140.984 75.9677C141.039 76.1856 140.93 76.3491 140.712 76.4036L30.1551 108.824C30.1006 108.879 30.1006 108.879 30.0461 108.879ZM7.43344 31.8321L30.3185 108.007L140.167 75.8042L123.548 7.03993L7.43344 31.8321Z"
      fill="#231F20"
    />
    <path
      d="M9.23298 39.6252C9.01503 39.6252 8.85156 39.4617 8.85156 39.2438C8.85156 39.0803 8.96054 38.9169 9.124 38.8624L125.456 13.3618C125.674 13.3073 125.892 13.4163 125.947 13.5798C126.001 13.7977 125.892 14.0157 125.729 14.0702H125.674L9.34196 39.6252H9.23298Z"
      fill="#231F20"
    />
    <path
      d="M14.6819 35.4293C15.5245 35.4293 16.2076 34.7462 16.2076 33.9036C16.2076 33.061 15.5245 32.3779 14.6819 32.3779C13.8393 32.3779 13.1562 33.061 13.1562 33.9036C13.1562 34.7462 13.8393 35.4293 14.6819 35.4293Z"
      fill="#27AE60"
    />
    <path
      d="M20.1819 34.0123C21.0245 34.0123 21.7076 33.3292 21.7076 32.4866C21.7076 31.644 21.0245 30.9609 20.1819 30.9609C19.3393 30.9609 18.6562 31.644 18.6562 32.4866C18.6562 33.3292 19.3393 34.0123 20.1819 34.0123Z"
      fill="#D1D3D4"
    />
    <path
      d="M25.4671 32.8121C26.3097 32.8121 26.9927 32.129 26.9927 31.2864C26.9927 30.4438 26.3097 29.7607 25.4671 29.7607C24.6245 29.7607 23.9414 30.4438 23.9414 31.2864C23.9414 32.129 24.6245 32.8121 25.4671 32.8121Z"
      fill="#D1D3D4"
    />
    <path
      d="M36.9104 44.8005L108.181 28.0726C109.761 27.6911 111.287 28.6719 111.668 30.2521L112.213 32.5406C112.595 34.1208 111.614 35.6464 110.034 36.0279L38.763 52.7558C37.1828 53.1372 35.6571 52.1564 35.2757 50.5762L34.7308 48.2877C34.4039 46.7621 35.3847 45.1819 36.9104 44.8005Z"
      fill="#D1D3D4"
    />
    <path
      d="M122.076 68.2864C122.076 68.2864 89.3829 81.1457 58.5426 73.5173C27.7022 65.8889 9.28516 39.2441 9.28516 39.2441L25.7951 94.3318C25.7951 94.3318 37.5101 101.579 66.4434 101.197C103.386 100.761 128.288 82.3444 128.288 82.3444L119.842 67.905"
      fill="white"
    />
    <path
      d="M64.537 101.633C37.1839 101.633 25.7414 94.7672 25.6324 94.6583C25.5779 94.6038 25.4689 94.5493 25.4689 94.4403L8.90451 39.3526C8.85002 39.1347 8.959 38.9167 9.17695 38.8622C9.34042 38.8077 9.50388 38.8622 9.61286 39.0257C9.77632 39.2981 28.3023 65.616 58.6523 73.1354C84.6432 79.565 112.214 71.2828 119.897 68.6673L119.57 68.1225C119.461 67.959 119.516 67.6866 119.679 67.5776C119.843 67.4686 120.115 67.5231 120.224 67.6866L120.605 68.3404C121.477 68.0135 121.913 67.85 121.968 67.85C122.186 67.7955 122.404 67.85 122.458 68.068C122.513 68.2859 122.458 68.5039 122.24 68.5584C122.186 68.5584 121.75 68.7218 120.987 68.9943L128.615 82.126C128.724 82.2894 128.67 82.5074 128.506 82.6164C128.234 82.7798 103.06 101.088 66.4441 101.524L64.537 101.633ZM26.1228 94.1134C27.4305 94.8762 39.4724 101.197 66.4441 100.87C100.772 100.489 124.965 84.3055 127.798 82.2894L120.224 69.3757C112.65 71.9911 84.7522 80.3823 58.4344 73.8982C32.3345 67.4686 14.9527 47.2534 10.2667 41.2052L26.1228 94.1134Z"
      fill="#231F20"
    />
    <path
      d="M75.816 87.8456C64.0465 87.8456 50.5334 85.884 38.2191 79.3999C38.0556 79.2909 37.9466 79.0729 38.0556 78.9095C38.1646 78.746 38.3825 78.637 38.6005 78.746C68.46 94.5476 105.839 83.1051 106.22 82.9961C106.438 82.9416 106.656 83.0506 106.711 83.2685C106.765 83.4865 106.656 83.7045 106.438 83.7589C96.467 86.4289 86.1687 87.8456 75.816 87.8456Z"
      fill="#D1D3D4"
    />
    <path
      d="M69.2235 95.0943C44.6493 95.0943 28.6297 83.7063 28.4118 83.5973C28.2483 83.4883 28.1938 83.2159 28.3028 83.0524C28.4118 82.8889 28.6842 82.8344 28.8477 82.9434C29.0656 83.1069 51.1879 98.745 83.227 93.0782C83.4449 93.0238 83.6084 93.1872 83.6629 93.3507C83.7174 93.5686 83.5539 93.7321 83.3359 93.7866C78.7044 94.6584 73.964 95.0943 69.2235 95.0943Z"
      fill="#D1D3D4"
    />
    <path
      d="M44.8662 76.131C44.8117 76.131 44.7572 76.131 44.7027 76.0765C20.6734 65.1789 14.8431 51.7747 14.7342 51.6113C14.6252 51.3933 14.7342 51.1754 14.9521 51.1209C15.1701 51.0119 15.388 51.1209 15.4425 51.3388C15.4425 51.3933 16.8592 54.6626 21.2183 59.2941C25.1959 63.5442 32.6063 69.8104 44.9751 75.4227C45.1931 75.5317 45.2476 75.7496 45.1931 75.9131C45.1386 76.022 45.0296 76.131 44.8662 76.131Z"
      fill="#D1D3D4"
    />
    <path
      d="M87.8033 80.4366C87.5853 80.4366 87.4219 80.2731 87.4219 80.0552C87.4219 79.8372 87.5853 79.6737 87.8033 79.6737C87.9123 79.6737 101.153 79.6192 107.201 76.7314C107.419 76.6224 107.637 76.7314 107.692 76.8948C107.746 77.0583 107.692 77.3307 107.528 77.3852C101.371 80.4366 88.3482 80.4366 87.8033 80.4366Z"
      fill="#D1D3D4"
    />
    <path d="M113.902 70.3023L124.092 86.1039L132.973 83.107L120.277 63.4912L113.902 70.3023Z" fill="white" />
    <path
      d="M124.095 86.4849C123.986 86.4849 123.823 86.4304 123.768 86.3214L113.524 70.5198C113.415 70.3563 113.47 70.1929 113.579 70.0294L119.954 63.2184C120.008 63.1094 120.172 63.1094 120.281 63.1094C120.39 63.1094 120.499 63.1639 120.553 63.2728L133.195 82.8886C133.303 83.0521 133.249 83.3245 133.031 83.4335H132.977L124.149 86.4304C124.204 86.4849 124.149 86.4849 124.095 86.4849ZM114.396 70.3563L124.258 85.6675L132.323 82.9431L120.172 64.1446L114.396 70.3563Z"
      fill="#231F20"
    />
    <path
      d="M161.8 75.6963C161.8 75.6963 161.964 87.3568 155.207 95.8025C148.451 104.248 127.473 108.444 127.473 108.444L134.066 82.7798L161.8 75.6963Z"
      fill="#27AE60"
    />
    <path
      d="M127.471 108.878C127.362 108.878 127.253 108.824 127.199 108.77C127.09 108.661 127.09 108.552 127.09 108.388L133.683 82.7241C133.737 82.6151 133.846 82.5061 133.955 82.4516L161.69 75.3682C161.799 75.3682 161.908 75.3682 162.017 75.4227C162.126 75.4771 162.18 75.5861 162.18 75.7496C162.18 75.8586 162.289 87.628 155.478 96.0737C148.667 104.519 128.398 108.715 127.526 108.878H127.471ZM134.391 83.16L127.962 108.007C131.449 107.244 148.885 103.048 154.879 95.5833C160.709 88.2819 161.363 78.474 161.417 76.24L134.391 83.16Z"
      fill="#231F20"
    />
    <path
      d="M138.64 39.8425C138.64 39.8425 128.124 34.8841 117.608 37.6085C107.091 40.3329 94.668 57.6602 94.668 57.6602L120.768 62.1827L138.64 39.8425Z"
      fill="#27AE60"
    />
    <path
      d="M120.77 62.5651H120.716L94.616 58.0426C94.398 57.9881 94.2891 57.8246 94.2891 57.6067C94.2891 57.5522 94.3436 57.4977 94.3436 57.4432C94.8339 56.7349 106.985 39.9525 117.501 37.2281C128.017 34.5036 138.697 39.4621 138.806 39.5166C138.915 39.5711 138.969 39.68 139.024 39.789C139.078 39.898 139.024 40.007 138.969 40.1159L121.043 62.4562C120.988 62.5651 120.879 62.6196 120.77 62.5651ZM95.3243 57.3887L120.607 61.7478L138.043 40.007C135.918 39.1352 126.764 35.6479 117.719 37.9909C108.456 40.3339 97.4494 54.5009 95.3243 57.3887Z"
      fill="#231F20"
    />
    <path
      d="M114.992 61.5825C122.185 51.0663 134.826 39.2423 150.736 28.9985C174.875 13.4148 196.016 18.6457 203.154 21.1522C204.734 21.6971 205.77 23.2772 205.606 24.9664C204.952 32.5402 200.975 53.9541 176.891 69.5378C160.98 79.8361 145.015 86.4292 132.483 88.6632C132.483 88.6632 127.742 85.8843 121.64 76.4578C115.537 67.0313 114.992 61.5825 114.992 61.5825Z"
      fill="#27AE60"
    />
    <path
      d="M132.485 89.0999C132.431 89.0999 132.376 89.0999 132.267 89.0454C132.049 88.9364 127.418 86.103 121.315 76.731C115.212 67.359 114.613 61.9102 114.559 61.6922C114.559 61.5832 114.559 61.5288 114.613 61.4198C122.078 50.5221 135.155 38.5892 150.466 28.7268C174.441 13.2521 195.365 18.0471 203.211 20.8805C204.955 21.4798 206.099 23.2235 205.935 25.1306C205.227 33.4673 201.031 54.4998 177.057 69.9745C161.745 79.8368 145.508 86.8658 132.485 89.2088V89.0999ZM115.376 61.6922C115.539 62.564 116.52 67.8494 121.969 76.2951C127.418 84.7408 131.777 87.8466 132.54 88.337C145.453 85.994 161.527 79.074 176.675 69.2661C200.378 53.9549 204.519 33.1948 205.227 25.0216C205.336 23.4959 204.464 22.0792 202.993 21.5888C195.201 18.8644 174.605 14.0694 150.902 29.3806C135.809 39.1341 122.841 50.9035 115.376 61.6922Z"
      fill="#231F20"
    />
    <path
      d="M205.608 25.0233C204.682 34.3953 201.358 43.3859 196.018 51.1777L175.367 19.1386C184.63 17.4495 194.22 18.1578 203.156 21.1547C204.736 21.754 205.772 23.3342 205.608 25.0233Z"
      fill="white"
    />
    <path
      d="M196.069 51.5578C195.96 51.5578 195.797 51.5033 195.743 51.3943L175.037 19.3552C174.982 19.2462 174.982 19.1372 175.037 18.9738C175.091 18.8648 175.2 18.7558 175.309 18.7558C188.278 16.5218 198.412 19.0828 203.316 20.8264C205.06 21.4257 206.204 23.1694 206.041 25.0765C205.605 30.2529 203.752 40.5512 196.396 51.4488C196.287 51.5033 196.178 51.5578 196.069 51.5578ZM176.018 19.4097L196.069 50.468C203.098 39.8973 204.842 29.9804 205.278 24.9675C205.387 23.4418 204.515 22.0251 203.044 21.5347C198.303 19.8456 188.55 17.3391 176.018 19.4097Z"
      fill="#231F20"
    />
    <path
      d="M167.247 60.6561C175.372 60.6561 181.959 54.0694 181.959 45.9443C181.959 37.8191 175.372 31.2324 167.247 31.2324C159.122 31.2324 152.535 37.8191 152.535 45.9443C152.535 54.0694 159.122 60.6561 167.247 60.6561Z"
      fill="white"
    />
    <path
      d="M167.3 61.0391C158.964 61.0391 152.207 54.2825 152.207 45.9458C152.207 37.6091 158.964 30.8525 167.3 30.8525C175.637 30.8525 182.394 37.6091 182.394 45.9458C182.394 51.0677 179.778 55.8627 175.474 58.6416C173.022 60.1673 170.188 61.0391 167.3 61.0391ZM167.246 31.6154C159.29 31.6154 152.861 38.045 152.861 46.0003C152.861 53.9556 159.29 60.3852 167.246 60.3852C175.201 60.3852 181.631 53.9556 181.631 46.0003C181.631 39.2437 176.89 33.4135 170.297 31.9423C169.262 31.7244 168.281 31.6154 167.246 31.6154Z"
      fill="#231F20"
    />
    <path
      d="M167.248 55.0458C172.274 55.0458 176.348 50.9718 176.348 45.9462C176.348 40.9207 172.274 36.8467 167.248 36.8467C162.222 36.8467 158.148 40.9207 158.148 45.9462C158.148 50.9718 162.222 55.0458 167.248 55.0458Z"
      fill="#231F20"
    />
    <path
      d="M167.301 55.428C162.07 55.428 157.82 51.1779 157.82 46.0015C157.82 40.7706 162.07 36.5205 167.247 36.5205C172.478 36.5205 176.728 40.7706 176.728 45.947C176.728 49.1618 175.093 52.1587 172.369 53.9023C170.898 54.8831 169.099 55.3735 167.301 55.428ZM167.247 37.2289C165.558 37.2289 163.923 37.7193 162.561 38.6456C158.529 41.261 157.33 46.6553 159.945 50.6875C162.561 54.7196 167.955 55.9184 171.987 53.3029C176.019 50.6875 177.218 45.2931 174.603 41.261C172.968 38.7545 170.244 37.2289 167.247 37.2289Z"
      fill="#231F20"
    />
    <path
      d="M173.348 42.4031C173.185 42.4031 173.076 42.3486 173.022 42.1852C172.913 41.9672 170.079 37.0633 164.194 39.7877C164.031 39.8967 163.759 39.7877 163.704 39.6242C163.595 39.4607 163.704 39.1883 163.867 39.1338C170.406 36.137 173.621 41.8037 173.675 41.8582C173.784 42.0217 173.73 42.2941 173.512 42.3486C173.457 42.3486 173.403 42.3486 173.348 42.4031Z"
      fill="white"
    />
    <path
      d="M174.384 45.5633C174.166 45.5633 174.003 45.3454 174.003 45.1274C174.003 44.637 173.948 44.1466 173.73 43.7107C173.621 43.5472 173.676 43.2748 173.894 43.1658C174.057 43.0568 174.33 43.1113 174.439 43.3293V43.3838C174.711 43.9287 174.82 44.528 174.766 45.1819C174.711 45.3998 174.548 45.5633 174.384 45.5633Z"
      fill="white"
    />
    <path
      d="M119.625 61.1462C119.516 61.1462 119.462 61.0918 119.407 61.0918C119.244 60.9828 119.189 60.7103 119.353 60.5469C124.801 54.0083 130.632 47.7966 136.789 41.9663C136.952 41.8029 137.17 41.8029 137.334 41.9663C137.497 42.1298 137.497 42.3478 137.334 42.5112C131.177 48.3415 125.401 54.4987 119.952 61.0373C119.843 61.0373 119.734 61.0918 119.625 61.1462Z"
      fill="white"
    />
    <path
      d="M136.733 71.6091C126.108 78.4746 112.432 85.1767 111.233 83.3786C110.034 81.5805 121.804 71.827 132.429 64.9615C143.054 58.096 149.702 58.5319 149.702 58.5319C149.702 58.5319 147.359 64.7436 136.733 71.6091Z"
      fill="#27AE60"
    />
    <path
      d="M112.104 83.9785C111.668 84.033 111.232 83.8696 110.905 83.5426C110.632 83.1612 109.87 81.908 117.171 75.6963C121.966 71.6642 126.979 67.959 132.264 64.5807C142.89 57.7152 149.483 58.0966 149.755 58.0966C149.864 58.0966 149.973 58.1511 150.028 58.2601C150.082 58.369 150.082 58.478 150.082 58.587C149.973 58.8594 147.576 65.0166 136.95 71.8821C129.921 76.4592 116.354 84.033 112.104 83.9785ZM149.156 58.9139C147.358 58.9684 141.309 59.6767 132.7 65.289C127.469 68.6673 122.456 72.3725 117.716 76.3502C112.376 80.9272 111.395 82.8343 111.613 83.1612C112.485 84.4689 124.745 78.9656 136.623 71.2828C145.233 65.725 148.338 60.4941 149.156 58.9139Z"
      fill="#231F20"
    />
    <path
      d="M71.0729 66.7594L69.1658 64.7978L66.4414 65.1792L67.8036 62.7818L66.5504 60.3298L69.2748 60.8747L71.2364 58.9131L71.5633 61.6375L73.9608 62.8907L71.5088 64.0895L71.0729 66.7594Z"
      fill="#27AE60"
    />
    <path
      d="M96.0287 69.2108L94.83 67.9576L93.1953 68.23L94.0126 66.7588L93.2498 65.2331L94.8845 65.5601L96.0832 64.3613L96.3012 66.0505L97.8268 66.8133L96.3012 67.5217L96.0287 69.2108Z"
      fill="#27AE60"
    />
    <path
      d="M84.6965 116.344L80.7189 114.001L76.5233 115.962L77.5041 111.44L74.3438 108.062L78.9753 107.571L81.2093 103.539L83.0619 107.789L87.5844 108.661L84.1516 111.712L84.6965 116.344Z"
      fill="#27AE60"
    />
    <path
      d="M56.8527 87.8995L54.3462 85.2295L50.75 85.7744L52.4936 82.6141L50.859 79.3448L54.4552 80.0531L57.0162 77.4922L57.5065 81.0884L60.7214 82.7776L57.3976 84.3032L56.8527 87.8995Z"
      fill="#27AE60"
    />
  </svg>
);
